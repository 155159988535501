export const userConstants = {

    LOGIN_REQUEST:'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAIL: 'LOGIN_FAIL',

    LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
    LOGOUT_FAIL: 'LOGOUT_FAIL',

    REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
    REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAIL:'REGISTER_USER_FAIL',

    LOAD_USER_REQUEST: 'LOAD_USER_REQUEST',
    LOAD_USER_SUCCESS: 'LOAD_USER_SUCCESS',
    LOAD_USER_FAIL: 'LOAD_USER_FAIL',

    UPDATE_PROFILE_REQUEST : 'UPDATE_PROFILE_REQUEST',
    UPDATE_PROFILE_SUCCESS : 'UPDATE_PROFILE_SUCCESS',
    UPDATE_PROFILE_RESET : 'UPDATE_PROFILE_RESET',
    UPDATE_PROFILE_FAIL : 'UPDATE_PROFILE_FAIL',

    UPDATE_PASSWORD_REQUEST : 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS : 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_RESET : 'UPDATE_PASSWORD_RESET',
    UPDATE_PASSWORD_FAIL : 'UPDATE_PASSWORD_FAIL',

    FORGOT_PASSWORD_REQUEST : 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS : 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAIL : 'FORGOT_PASSWORD_FAIL',

    NEW_PASSWORD_REQUEST : 'NEW_PASSWORD_REQUEST',
    NEW_PASSWORD_SUCCESS : 'NEW_PASSWORD_SUCCESS',
    NEW_PASSWORD_FAIL : 'NEW_PASSWORD_FAIL',

    NEW_USERS_REQUEST : 'NEW_USERS_REQUEST',
    NEW_USERS_SUCCESS : 'NEW_USERS_SUCCESS',
    NEW_USERS_FAIL : 'NEW_USERS_FAIL',

    ALL_USERS_REQUEST : 'ALL_USERS_REQUEST',
    ALL_USERS_SUCCESS : 'ALL_USERS_SUCCESS',
    ALL_USERS_FAIL : 'ALL_USERS_FAIL',

    USER_DETAILS_REQUEST : 'USER_DETAILS_REQUEST',
    USER_DETAILS_SUCCESS : 'USER_DETAILS_SUCCESS',
    USER_DETAILS_FAIL : 'USER_DETAILS_FAIL',

    UPDATE_USER_REQUEST : 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS : 'UPDATE_USER_SUCCESS',
    UPDATE_USER_RESET : 'UPDATE_USER_RESET',
    UPDATE_USER_FAIL : 'UPDATE_USER_FAIL',

    DELETE_USER_REQUEST : 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS : 'DELETE_USER_SUCCESS',
    DELETE_USER_RESET : 'DELETE_USER_RESET',
    DELETE_USER_FAIL : 'DELETE_USER_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS',
    
};

