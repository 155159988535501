import { productConstants } from "../../constants/productConstants";
import axios from "axios";
import { toast } from 'react-toastify';

export const products = () => {

    return (dispatch) => {
        dispatch({
            type: productConstants.ALL_PRODUCTS_REQUEST
        });

        axios.get('/products')
            .then((response) => {
                console.log('products', response);
                if (response.data.success === true) {
                    const { products } = response.data
                    const productsCount = response.data.page_meta.total_items_count

                    dispatch({
                        type: productConstants.ALL_PRODUCTS_SUCCESS,
                        payload: { products: products, productsCount: productsCount }
                    });
                } else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);

                        dispatch({
                            type: productConstants.ALL_PRODUCTS_FAIL,
                            payload: { error: response.data.full_messages[0] }
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });
    }
}

export const newProduct = (product) => {
    // console.log()

    return (dispatch) => {
        dispatch({
            type: productConstants.NEW_PRODUCT_REQUEST
        });

        console.log(product.images)
        console.log(product.category_id)

        let formData = new FormData();
        formData.append('etat', 1);
        formData.append('status', 1);
        formData.append('name', product.nom);
        formData.append('unit', product.unite);
        formData.append('reference', product.reference);
        formData.append('description', product.description);
        formData.append('category', product.category_id.label);
        formData.append('images', product.images);
        formData.append('utilisateurid', localStorage.getItem('userid'))

        console.log(formData)

        const token = localStorage.getItem('token');
        // console.log(token)
        let config = {
            headers: {
                'USER-KEY': `Bearer ${token}`,
                'Content-type': 'multipart/form-data'
            }
        }

        // // console.log( 'asss', token)
        axios.post('/product', formData, config)
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    const { success } = response.data
                    // const productCount = response.data.success
                    // console.log(product)
                    console.log(success)
                    dispatch({
                        type: productConstants.NEW_PRODUCT_SUCCESS,
                        payload: { success: success }
                    })
                    toast.success('Ajouter avec succes!!')
                }
                else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        // const {success} = response.data
                        // toast.error(response.data.full_messages[0]);
                        dispatch({
                            type: productConstants.NEW_PRODUCT_FAIL
                            // payload: { error: response.data.full_messages[0] }
                        });
                        for (var i = 0; i < response.data.full_messages[i]; i++) {
                            toast.error(response.data.full_messages[i])
                        }
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });

    }
}

export const editProduct = (product) => {
    // console.log()

    return (dispatch) => {
        dispatch({
            type: productConstants.UPDATE_PRODUCT_REQUEST
        });

        // console.log(product.machine_id.value)
        // console.log(product.category_id.value)
        // console.log(product.category_id)
        console.log('pro', product)
        let formData = new FormData();
        formData.append('etat', 1);
        formData.append('status', product.status);
        formData.append('name', product.nom);
        formData.append('unit', product.unite);
        formData.append('reference', product.reference);
        formData.append('description', product.description);
        formData.append('category', product.category.label);
        // formData.append('category', product.category_id.label);
        formData.append('images', product.images);
        formData.append('utilisateurid', localStorage.getItem('userid'))

        console.log(formData)

        const token = localStorage.getItem('token');
        // console.log(token)
        let config = {
            headers: {
                'USER-KEY': `Bearer ${token}`,
                'Content-type': 'multipart/form-data'
            }
        }

        // // console.log( 'asss', token)
        axios.put(`/product/${product.id}`, formData, config)
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    const { success } = response.data

                    dispatch({
                        type: productConstants.UPDATE_PRODUCT_SUCCESS,
                        payload: { isUpdated: success }
                    })
                    toast.success('Modifier avec succes!!')
                }
                else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        // const {success} = response.data
                        // toast.error(response.data.full_messages[0]);
                        dispatch({
                            type: productConstants.UPDATE_PRODUCT_FAIL,
                            payload: { error: response.data.errors[0].message }
                        });
                        toast.error(response.data.errors[0].messager)

                        // for(var i=0; i<response.data.full_messages[i]; i++){
                        //     toast.error(response.data.full_messages[i])
                        // }
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });

    }
}

export const detailProduct = (productId) => {
    console.log('id', productId)
    return (dispatch) => {
        dispatch({
            type: productConstants.PRODUCT_DETAILS_REQUEST
        });
        // console.log(productId)
        axios.get(`/product/${productId}`)
            .then((response) => {
                console.log('details products', response);
                if (response.data.success === true || response.status === 200) {
                    const product = response.data
                    // console.log(product)
                    dispatch({
                        type: productConstants.PRODUCT_DETAILS_SUCCESS,
                        payload: { product: product }
                    });
                } else {
                    // if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    toast.error(response.data.full_messages[0]);

                    dispatch({
                        type: productConstants.PRODUCT_DETAILS_FAIL,
                        payload: { error: response.data.full_messages[0] }
                    });
                    // }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });
    }
}

export const deleteProduct = (productID) => {
    // console.log()

    return (dispatch) => {
        dispatch({
            type: productConstants.DELETE_PRODUCT_REQUEST
        });

        const token = localStorage.getItem('token');
        let config = {
            headers: {
                'USER-KEY': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }

        // console.log(productID)
        axios.delete(`/product/${productID}`, config)
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    const { success } = response.data
                    console.log(success)

                    // axios.get('products')
                    //     .then((response))
                    dispatch({
                        type: productConstants.DELETE_PRODUCT_SUCCESS,
                        payload: { isDeleted: success }
                    })
                } else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);
                        dispatch({
                            type: productConstants.DELETE_PRODUCT_FAIL,
                            payload: { error: response.data.full_messages[0] }
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });

    }
}
export const deactivateProduct = (productID) => {
    console.log('productActions: ', { productID })

    return (dispatch) => {
        dispatch({
            type: productConstants.DEACTIVATE_PRODUCT_REQUEST
        });

        const token = localStorage.getItem('token');

        axios.defaults.headers = {
            'USER-KEY': `Bearer ${token}`,
        }
        
        let config = {
            headers: {
                'user-key': `Bearer ${token}`,
                // 'Content-Type': 'application/json'
            }
        }

        console.log({ config })

        // console.log(productID)
        axios.post(`/product/${productID}/status/0`, config)
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    const { success } = response.data
                    console.log(success)

                    // axios.get('products')
                    //     .then((response))
                    dispatch({
                        type: productConstants.DEACTIVATE_PRODUCT_SUCCESS,
                        payload: { isDeactivate: success }
                    })
                } else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);
                        dispatch({
                            type: productConstants.DEACTIVATE_PRODUCT_FAIL,
                            payload: { error: response.data.full_messages[0] }
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
                // console.log({error});
            });

    }
}

export const activateProduct = (productID) => {
    console.log('productActions: ', { productID })

    return (dispatch) => {
        dispatch({
            type: productConstants.DEACTIVATE_PRODUCT_REQUEST
        });

        const token = localStorage.getItem('token');

        axios.defaults.headers = {
            'USER-KEY': `Bearer ${token}`,
        }
        
        let config = {
            headers: {
                'user-key': `Bearer ${token}`,
                // 'Content-Type': 'application/json'
            }
        }

        console.log({ config })

        // console.log(productID)
        axios.post(`/product/${productID}/status/1`, config)
            .then((response) => {
                console.log(response)
                if (response.data.success === true) {
                    const { success } = response.data
                    console.log(success)

                    // axios.get('products')
                    //     .then((response))
                    dispatch({
                        type: productConstants.ACTIVATE_PRODUCT_SUCCESS,
                        payload: { isactivate: success }
                    })
                } else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);
                        dispatch({
                            type: productConstants.ACTIVATE_PRODUCT_FAIL,
                            payload: { error: response.data.full_messages[0] }
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
                // console.log({error});
            });

    }
}
