import React, { Component, Fragment, useState, useEffect, useRef } from 'react'
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import Select from 'react-select';
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from 'react-toastify';
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import * as Yup from 'yup'
import { connect, useDispatch } from "react-redux";
import Breadcrumb from '../common/breadcrumb';
import * as slideActions from "../../redux/actions/slideActions";
import * as roleActions from "../../redux/actions/roleActions";

const typeChoices = [
    { value: 'home', label: 'Home' },
    { value: 'produit', label: 'Produit' },
]

const types = ['home', 'produit']

const formatChoices = [
    { value: 'image', label: 'Image' },
    { value: 'video', label: 'Video' }
]

const formats = ['image', 'video']

export const CreateSlide = (props) => {
    const [format, setFormat] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const [visible, setVisible] = useState(false)
    const [roles, setRoles] = useState(null)
    const [loading, setLoading] = useState(false)
    const [videoDuration, setVideoDuration] = useState(-1)
    // const newSlide = { type, format, file, isLoading, visible, roles, loading, videoDuration }
    const [durationTrigger, setDurationTrigger] = useState(null)
    const [mime, setMime] = useState(null)
    // const [accept, setAccept] = useState()
    const accept = {
        image: 'image/*',
        video: 'video/*, .mkv, .avi, .m4v, .mp4'
    };
    const inputRef = useRef()

    const initialValues = {
        format: '',
        type: '',
        file: null
    }

    const dispatch = useDispatch()

    const onSubmit = values => {
        console.log({ values })
        // handleSubmitChange()

        setIsLoading(true)
        const newSlide = {
            type: values.type,
            format: values.format,
            file: values.file,
            isLoading,
            visible,
            roles,
            loading,
            videoDuration
        }
        console.log({ newSlide })
        // props.newSlide(newSlide)
        dispatch(slideActions.newSlide(newSlide))
        setTimeout(() => {
            setIsLoading(false)
            setSubmitting(false)
            window.location = '/slides/list-slide'
        }, 500)
        if (props.addslide.success === true) {
            props.history.push('/slides/list-slide')
        } else {
            props.history.push('/slides/create-slide')
            setIsLoading(false)
            setSubmitting(false)
        }
    }

    // const handleSubmitChange = () => {
    //     console.log({ type, format, file })
    //     // e.preventDefault();
    //     setIsLoading(true)
    //     console.log({ props, newSlide })
    //     props.newSlide(newSlide)
    //     // console.log(this.props.addslide.success)
    //     if (props.addslide.success === true) {
    //         props.history.push('/slides/list-slide');
    //     } else {
    //         props.history.push('/slides/create-slide');
    //         setIsLoading(false)
    //         setSubmitting(false)
    //     }
    // }

    const validate = formValues => {
        // console.log({ validateValues: formValues })
        const errors = {}

        if (!formValues.format) {
            errors.format = 'Le format est invalide'
            errors.file = "Le format et le type fichier ne correspondent pas"
        }

        // if (!formats.includes(values.format)) {
        //     errors.format = 'Not available format' + formats
        // }

        if (!formValues.type) {
            errors.type = 'Le type du slide est invalide'
        }

        // if (!types.includes(values.type)) {
        //     errors.type = 'Not available type' + types
        // }

        if (formValues.file === null) {
            errors.file = 'Selectionner un fichier'
        }

        if ((formValues.format === 'image' && videoDuration > 0) || (formValues.format === 'video' && videoDuration === 0)) {
            errors.file = "Le format et le type fichier ne correspondent pas"
        }

        if ((formValues.format === 'video' && videoDuration > 30)) {
            errors.file = "La vidéo est trop longue (>30s)"
        }

        return errors
    }

    /* const validationSchema = Yup.object().shape({
        format: Yup.string().required('Invalid Format'),
        type: Yup.string().required('Invalid Type'),
        file: Yup.object().required('This field is required').when('format', {
            is: (format) => format === 'video' && videoDuration > 30,
            then: Yup.date('Video too long (>30s)'),
            otherwise: Yup.object().nullable(),
        })
    }) */

    const handleTypeChange = (option) => {
        // setTimeout(() => {
        // setType(option.value)
        // }, 1000)
        // console.log({ option, type })
        // return option.value
        setFieldTouched('type', true)
        setFieldValue('type', option.value)
    }

    const handleFormatChange = (option) => {
        // setTimeout(() => {
        //     setFormat(option.value)
        //     if(option.value === 'image') {
        //         setAccept('image/*')
        //     } else {
        //         setAccept('video/*, .mkv, .avi, .m4v, .mp4')
        //     }
        //     setFile(null)
        //     inputRef.current.value = null
        // }, 1000)
        // console.log({ option })
        // return option.value
        setFieldValue('file', null)
        inputRef.current.value = null
        setVideoDuration(-1)
        setTimeout(() => {
            setFieldTouched('format', true)
            setFieldValue('format', option.value)
            setFormat(option.value)
        }, 100);
    }

    useEffect(() => {
        if (durationTrigger) {
            setVideoDuration(durationTrigger.path[0].duration)
            setDurationTrigger(null)
        }
    }, [durationTrigger])

    const loadFile = (e) => {
        let blob = new Blob([e.target.result], { type: mime })
        let url = (URL).createObjectURL(blob)
        let video = document.createElement("video")

        video.preload = "metadata";
        video.addEventListener("loadedmetadata", (e) => setDurationTrigger(e));
        video.src = url;
    };

    const handleFileChange = (e) => {
        setFieldTouched('file', true)
        let inputFile = e.target.files[0]
        let fileType = inputFile.type
        // console.log({ file: inputFile, fileType })
        if (fileType.includes('image')) {
            setVideoDuration(0)
            // setFile(inputFile)
            setFieldValue('file', inputFile)
            // return inputFile
        } else if (fileType.includes('video')) {
            setMime(inputFile.type)
            let rd = new FileReader()

            rd.onload = loadFile
            rd.readAsArrayBuffer(inputFile);

            // setFile(inputFile)
            // return inputFile
            /**
             * Important to have this here as setVideoDuration must have finished before we set the file form file values
             * Otherwise, the validation won't read the updated value of VideoDuration during validation and wrong validation
             * will be done.
             */
            setTimeout(() => {
                setFieldValue('file', inputFile)
            }, 700);
        } else {
            setVideoDuration(-1);
            // return inputFile
        }
    }

    let rolAjouterSlide = null;
    let rolListeSlide = null;
    useEffect(() => {
        setLoading(true)
        // props.actionsdetailRole(localStorage.getItem('roles'))

        setTimeout(() => {
            // console.log('props.roledetails.role[0]: ', JSON.parse(JSON.stringify(props.roledetails.role[0])))
            rolAjouterSlide = props.roledetails.role[0].ajouterSlide
            rolListeSlide = props.roledetails.role[0].listeSlide
            // console.log('rolAjouterSlide: ', rolAjouterSlide)
            // console.log({rolAjouterSlide, rolListeSlide})
            if (rolListeSlide == '1') {
                setVisible(true)
            }
            setRoles(rolAjouterSlide)
            setLoading(false)
        }, 1000)
    }, [])

    const {
        setFieldTouched, setSubmitting,
        values, setFieldValue,
        errors, handleSubmit,
        touched,
    } = useFormik({
        initialValues,
        onSubmit,
        validate
    })

    // const [state, setState] = useState({loading, roles, visible, type, format, isLoading})

    const customStyles = {
        input: (provided, state) => ({
            ...provided,
            margin: '0px',
            width: '715px'
        }),
    }

    return (
        loading
            ? <div style={{
                display: "flex", justifyContent: "center",
                alignItems: "center", width: "100%", height: "100vh"
            }}>
                <BeatLoader color={"#EC1C5B"} loading={loading} size={50} />
            </div>
            : (roles === '1')
                ? <Fragment>
                    <Breadcrumb title="Ajouter un slide" parent="Slides" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    {visible === true ?
                                        (
                                            <div className="card-header">
                                                <Link type="button" to="/slides/list-slide"
                                                    className="btn btn-primary">Retour</Link>
                                            </div>
                                        ) :
                                        null
                                    }
                                    <div className="card-body">
                                        <form className="needs-validation user-add" onSubmit={handleSubmit}>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Format :</label>
                                                <div className="col-xl-8 col-md-7">
                                                    <div className='row'>
                                                        <Select
                                                            className='col-12'
                                                            styles={customStyles}
                                                            name="Format"
                                                            // value={format}
                                                            // onChange={(option) => formik.setFieldValue('format', handleFormatChange(option))}
                                                            onChange={handleFormatChange}
                                                            options={formatChoices}
                                                        />
                                                        {/* <span>{formik.values.format}</span> */}
                                                        {/* <div style={{ color: 'red' }}>{formik.errors.format ? <div>{formik.errors.format}</div> : null}</div> */}
                                                        <div style={{ color: 'red' }}>{errors.format && touched.format ? <div>{errors.format}</div> : null}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4" ><span>*</span> Type :</label>
                                                <div className="col-xl-8 col-md-7">
                                                    <div className='row'>
                                                        <Select
                                                            className='col-12'
                                                            styles={customStyles}
                                                            name="type"
                                                            // value={type}
                                                            // onChange={(option) => formik.setFieldValue('type', handleTypeChange(option))}
                                                            onChange={handleTypeChange}
                                                            options={typeChoices}
                                                        />
                                                        {/* <span>{formik.values.type}</span> */}
                                                        {/* <div style={{ color: 'red' }}>{formik.errors.type ? <div>{formik.errors.type}</div> : null}</div> */}
                                                        <div style={{ color: 'red' }}>{errors.type && touched.type ? <div>{errors.type}</div> : null}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Fichier du slide : ({videoDuration})s</label>
                                                <div className='col-xl-8 col-md-7'>
                                                    <div className='row'>
                                                        <input className="form-control col-12"
                                                            type="file"
                                                            // accept={accept}
                                                            // onChange={(e) => formik.setFieldValue('file', handleFileChange(e))}
                                                            accept={accept[values.format || '']}
                                                            onChange={handleFileChange}
                                                            ref={inputRef}
                                                        />
                                                        {/* <div style={{ color: 'red' }}>{formik.errors.file ? <div>{formik.errors.file}</div> : null}</div> */}
                                                        <div style={{ color: 'red' }}>{errors.file && touched.file ? <div>{errors.file}</div> : null}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ToastContainer />
                                            <div className="offset-xl-3 offset-sm-4 mt-3">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                // disabled={!formValid}
                                                // onClick={() => handleSubmitChange()}
                                                >Ajouter</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
                : <Fragment>

                </Fragment>
    )
}

export const Loader = (props) => {
    return (
        <div style={{
            display: "flex", justifyContent: "center",
            alignItems: "center", width: "100%", height: "100vh"
        }}>
            <BeatLoader color={"#EC1C5B"} loading={props.loading} size={50} />
        </div>
    )
}

const mapStateToProps = (state) => {
    console.log({ state, addslide: state.addslide, roledetails: state.roledetails })
    return {
        addslide: state.addslide,
        roledetails: state.roledetails,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        // newSlide: (slide) => {dispatch(slideActions.newSlide(slide))},
        // actionsdetailRole: (rolename) => () => dispatch(roleActions.actionsdetailRole(rolename)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSlide)
