import React, { Component, Fragment } from 'react'
import { Link, withRouter, Redirect, Router } from 'react-router-dom';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import BeatLoader from "react-spinners/BeatLoader";
import { User, Settings, Archive, Box, Sliders, Tablet, Pocket, List, CreditCard, Download, Loader, Gift, DollarSign, Trash2 } from 'react-feather'
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { connect } from "react-redux";

import Breadcrumb from '../common/breadcrumb';

import Data_maintains from '../common/dataTables/data_maintains';
import Data_slides from '../common/dataTables/data_slides';
import Datatable from '../common/datatable'
import { Data_logs } from '../common/dataTables/data_logs';
import Data_currencies from '../common/dataTables/data_currencies';
import Data_products from '../common/dataTables/data_products';
import data from '../../assets/data/physical_list';

import * as roleActions from "../../redux/actions/roleActions";
import * as machineActions from "../../redux/actions/machineActions";
import * as transactionomActions from "../../redux/actions/transactionomActions";
import * as remboursementActions from "../../redux/actions/remboursementActions";
import * as paiementActions from "../../redux/actions/paiementActions";
import * as orderActions from "../../redux/actions/orderActions";
import * as maintenanceActions from "../../redux/actions/maintenanceActions";
import * as logActions from "../../redux/actions/logActions";
import * as appromonnaieActions from "../../redux/actions/appromonnaieActions";
import * as approproduitActions from "../../redux/actions/approproduitActions";
import * as allocproductActions from "../../redux/actions/allocproductActions";
import * as allocslideActions from "../../redux/actions/allocslideActions";

const stackTokens = { childrenGap: 10 };

export class Detail_machine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            transactionoms: [],
            remboursements: [],
            paiements: [],
            orders: [],
            params: null,
            logs: [],
            maintenances: [],
            currencies: [],

            alloc_products: [],
            products: [],

            alloc_slides: [],
            slides: [],

            data,
            open: false,
            id: null,
            nom: '',
            type: '',
            localisation: '',
            lien: '',
            montant: '',
            description: '',
            isLoading: false,
            visible: false,
            roles: null,
            loading: false,
            hiddenDialog: true,
            allocproductTouchedId: 0
            // eslint-disable-next-line no-undef            
        };

        this.handleDeleteProduct = this.handleDeleteProduct.bind(this)
        this.handleDialog = this.handleDialog.bind(this)

    }

    modalPropsStyles = { main: { maxWidth: 450 } };
    dialogContentProps = {
        type: DialogType.normal,
        title: "Suppression d'allocation de produit",
        subText: 'Voulez vraiment supprimer cette allocation de produit?',
    };

    toggleDialog = () => {
        this.setState(prevState => ({
            hiddenDialog: !prevState.hiddenDialog
        }));
        // console.log({hiddenDialog: this.state.hiddenDialog})
    }

    handleDialog = (allocproductId) => {
        console.log({ allocproductId })
        this.setState(state => ({ ...state, allocproductTouchedId: allocproductId }))
        setTimeout(() => {
            this.toggleDialog()
        }, 100)
    }

    handleDeleteProduct = () => {
        console.log({ allocproductTouchedId: this.state.allocproductTouchedId })
        this.props.deleteAllocProduct(this.state.allocproductTouchedId);
        this.toggleDialog()
        window.location.reload()

        // console.log(allocproductId)

        // setTimeout(() => {
        // axios.get('/products')
        //     .then((response) => {
        //         const { products } = response.data
        //         console.log(products)
        //         this.setState({
        //             products: products
        //         })
        //     })
        // },1000)
    }


    componentDidMount = () => {
        let listMaintenances = [];
        let listLogs = [];
        let listPaiements = [];
        let listRemboursements = [];
        let listTransactionoms = [];
        let listCurrencies = [];
        let listOrders = [];

        let listAllocProducts = [];
        let listProducts = [];
        
        /* let listAllocSlides
        let listSlides = [] */

        let link = process.env.REACT_APP_NEXT_PUBLIC_REST_API

        const param = this.props.match.params.id
        // console.log('param', this.props.match.params.id)
        this.setState({
            loading: true,
            params: param
        })

        this.props.maintenanceByMachine(param)

        setTimeout(() => {
            // console.log('maintenance',this.props.maintenancebymachine)
            this.props.maintenancebymachine.maintenances.map(maintenance => {
                let item = {
                    ID: maintenance.id,
                    Nom: maintenance.nom,
                    Type: maintenance.type,
                    Montant: maintenance.montant,
                    Machine: maintenance.machine.nom,
                    Piece: maintenance.piece_id ? maintenance.piece_id.nom : "Aucune Piece",
                    etat: maintenance.etat,
                    description: maintenance.description,
                    date: maintenance.dateMaintenance,
                    utilisateur: maintenance.utilisateurid,

                }
                listMaintenances.push(item);
            })
            console.log('listes', listMaintenances)
            this.setState({
                maintenances: listMaintenances
            })
        }, 1000)

        this.props.logsByMachine(param);

        setTimeout(() => {
            // console.log(this.props.logbymachine.logss)

            this.props.logbymachine.logss.map(log => {
                let item = {
                    ID: '#' + log.id,
                    Type: log.type,
                    etat: log.etat,
                    date: log.dateLogs,
                    description: log.description,
                    machine: log.machine_id,
                    utilisateur: log.utilisateurid
                }
                listLogs.push(item);
            })
            this.setState({
                logs: listLogs
            })
        }, 1000)

        this.props.appromonnaieByMachine(param)

        setTimeout(() => {
            // console.log('appro machine', this.props.appromonnaiebymachine.appromonnaies)

            this.props.appromonnaiebymachine.appromonnaies.map(appromonnaie => {

                let item = {
                    ID: appromonnaie.id,
                    etat: appromonnaie.etat,
                    quantite: <span className="badge badge-info">{appromonnaie.quantite} FCFA</span>,
                    // Machine: appromonnaie.machine.nom,
                    Description: appromonnaie.description,

                }
                listCurrencies.push(item);
            })
            this.setState({
                currencies: listCurrencies
            })
        }, 1000)

        this.props.approproduitByMachine(param)

        setTimeout(() => {
            // console.log('appro machine', this.props.approproduitbymachine)
            // if(this.props.approproduitbymachine) {
            this.props.approproduitbymachine.approproduits.map(approproduit => {
                // console.log('quannn', approproduit)
                let item = {
                    ID: approproduit.id,
                    etat: approproduit.etat,
                    quantite: <span className="badge badge-info">{approproduit.quantite} FCFA</span>,
                    // Machine: approproduit.machine.nom,
                    Product: approproduit.alloc_product.product.name,
                    Description: approproduit.description,

                }
                listProducts.push(item);
            })
            console.log('listedddd', listProducts)
            this.setState({
                products: listProducts
            })
            // }

        }, 1000)

        this.props.allocProductByMachine(param)

        setTimeout(() => {
            // console.log('alloc', this.props.allocproductbymachine)
            this.props.allocproductbymachine.alloc_products.map(alloc_product => {
                // console.log(link+alloc_product.product.images[0].filePath)
                let item = {
                    alloc_id: alloc_product.id,
                    product_id: alloc_product.product.product_id,
                    image: link + alloc_product.product.images[0].filePath,
                    Nom: alloc_product.product.name,
                    unit: alloc_product.product.unit,
                    reference: alloc_product.product.reference,
                    description: alloc_product.product.description,
                    categories: alloc_product.product.categories[0],
                    quantite: alloc_product.quantite,
                    price: alloc_product.price

                }
                listAllocProducts.push(item);
            })
            this.setState({
                alloc_products: listAllocProducts,

            })

        }, 1000)

        /**
         * Get all slide allocated to this machine
        **/
        /* this.props.allocSlideByMachine(param)

        setTimeout(() => {
            // console.log('alloc', this.props.allocslidebymachine)
            this.props.allocslidebymachine.alloc_slides.map(alloc_slide => {
                // console.log(link+alloc_slide.slide.images[0].filePath)
                let item = {
                    alloc_id: alloc_slide.id,
                    slide_id: alloc_slide.slide.slide_id,
                    image: link + alloc_slide.slide.images[0].filePath,
                }
                listAllocSlides.push(item);
            })
            this.setState({
                alloc_slides: listAllocSlides,

            })

        }, 1000) */

        this.props.ordersByMachine(param)

        setTimeout(() => {
            if (this.props.ordersbymachine.orders) {
                this.props.ordersbymachine.orders.map(order => {
                    let item = {
                        ID: order.id,
                        trackingNumber: order.trackingNumber,
                        machine_id: order.machine_id,
                        total_amount: order.total_amount,
                        comments: order.comments,
                        is_delivried: order.is_delivried,
                        // id_pay: order.paiement.id_pay,
                        reference: order.reference,
                        paiement_id: order.paiement.paiement_id,
                        remboursement_id: order.remboursement.remboursement_id,
                        etat: order.etat

                    }
                    listOrders.push(item);
                })
            }
            this.setState({
                orders: listOrders,
                loading: false
            })

        }, 1000)

        this.props.paiementsByMachine(param)

        setTimeout(() => {
            this.props.paiementsbymachine.paiements.map(paiement => {

                let item = {
                    ID: "#" + paiement.id,
                    methode: paiement.methode,
                    Montant: <span className="badge badge-info">{paiement.montant} FCFA</span>,
                    status: (paiement.status != "0") ? <span className="badge badge-success">OUI</span> : <span className="badge badge-warning">NON</span>,
                    Description: paiement.description,
                    Téléphone: paiement.telephone,
                }
                listPaiements.push(item);
            })

            this.setState({
                paiements: listPaiements,
                loading: false
            })
        }, 1000)

        this.props.remboursementsByMachine(param)

        setTimeout(() => {
            this.props.remboursementsbymachine.remboursements.map(remboursement => {
                console.log(remboursement.montant)
                let item = {
                    ID: "#" + remboursement.id,
                    methode: remboursement.methode,
                    Montant: <span className="badge badge-info">{remboursement.montant} FCFA</span>,
                    status: (remboursement.status !== "0") ? <span className="badge badge-success">OUI</span> : <span className="badge badge-warning">NON</span>,
                    Description: remboursement.description,
                    Machine: remboursement.machine.nom,
                    Téléphone: remboursement.telephone,
                }
                listRemboursements.push(item);
            })

            this.setState({
                remboursements: listRemboursements,
                loading: false
            })
        }, 1000)

        this.props.transactionomsByMachine(param)

        setTimeout(() => {
            this.props.transactionomsbymachine.transactionoms.map(transactionom => {
                console.log(transactionom)
                let item = {
                    etat: transactionom.etat,
                    ID: "#" + transactionom.id,
                    Time: transactionom.time,
                    dateTransaction: transactionom.dateTransaction,
                    Montant: <span className="badge badge-info">{transactionom.montant} FCFA</span>,
                    status: (transactionom.status !== "0") ? <span className="badge badge-success">OUI</span> : <span className="badge badge-warning">NON</span>,
                    Description: transactionom.description,
                    Machine: transactionom.machine.nom,
                    Téléphone: transactionom.telephone,
                }
                listTransactionoms.push(item);
            })

            this.setState({
                transactionoms: listTransactionoms,
                loading: false
            })
        }, 1000)



    }

    render() {
        const { loading, orders, transactionoms, remboursements, paiements, params, alloc_products, alloc_slides, products, visible, data, maintenances, logs, currencies } = this.state
        // this.state.visible = true
        const checkboxStyles = {
            root: {
                fontFamily: 'work-Sans, sans-serif'
            },
            checkmark: {}
        }
        if (loading) {
            return (
                <div style={{
                    display: "flex", justifyContent: "center",
                    alignItems: "center", width: "100%", height: "100vh"
                }}>
                    <BeatLoader color={"#EC1C5B"} loading={loading} size={50} />
                </div>
            )
        } else {
            return (
                <Fragment>
                    <Breadcrumb title="Informations de machine " parent="Machines / Liste des Machines" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    {/* {visible == true ? */}
                                    {/* ( */}
                                    <div className="card-header">
                                        <Link type="button" to="/machines/list-machine" className="btn btn-primary ">Retour</Link>
                                    </div>
                                    {/* ): */}
                                    {/* null */}
                                    {/* } */}
                                    <div className="card-body">
                                        <Tabs>
                                            <TabList className="nav nav-tabs tab-coupon justify-content-center" >
                                                <Tab className="nav-link"><Gift className="mr-2" />Produits</Tab>
                                                <Tab className="nav-link"><Sliders className="mr-2" />Slides</Tab>
                                                <Tab className="nav-link"><Loader className="mr-2" />Maintenances</Tab>
                                                <Tab className="nav-link"><Archive className="mr-2" />Logs</Tab>
                                                <Tab className="nav-link"><DollarSign className="mr-2" />Appro. Monnaies</Tab>
                                                <Tab className="nav-link"><Box className="mr-2" />Appro. Produits</Tab>
                                                <Tab className="nav-link"><Pocket className="mr-2" />Commandes</Tab>
                                                <Tab className="nav-link"><CreditCard className="mr-2" />Paiements</Tab>
                                                <Tab className="nav-link"><List className="mr-2" />Remboursements</Tab>
                                                <Tab className="nav-link"><Tablet className="mr-2" />Mobile Money</Tab>
                                            </TabList>

                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    <Link type="button" to={`/machines/add-product/machine/${params}`} className="btn btn-primary ">Affecter un produit</Link>
                                                    <div className="row products-admin ratio_asos">
                                                        {
                                                            alloc_products.map((myData, i) => {
                                                                // console.log('iiii', i)
                                                                // console.log('myData', myData)
                                                                return (
                                                                    <div className="col-xl-3 col-sm-6" key={i}>
                                                                        <div className="card">
                                                                            <div className="products-admin">
                                                                                <div className="card-body product-box">
                                                                                    <div className="img-wrapper">
                                                                                        <div className="lable-block">
                                                                                            {(myData.quantite) ? ((myData.quantite < 4) ? <span className="stock-quantity lable31">En Stock: {myData.quantite}</span> : <span className="stock-quantity lable3">En Stock: {myData.quantite}</span>) : <span className="stock-quantity lable32">En Stock: {myData.quantite}</span>}
                                                                                            {/* {(myData.discount === 'on sale' )?<span className="lable4">{myData.discount}</span> : '' } */}
                                                                                        </div>

                                                                                        <div className="front">
                                                                                            <a className="bg-size"><img className="img-fluid blur-up bg-img lazyloaded" src={myData.image} alt='' /></a>
                                                                                            <div className="product-hover">
                                                                                                <ul>

                                                                                                    <li>
                                                                                                        <button className="btn"
                                                                                                            type="button"
                                                                                                            onClick={() => this.handleDialog(myData.alloc_id)}
                                                                                                        ><Trash2 className="deleteBtn" />
                                                                                                        </button>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row'>
                                                                                        <div className="col-12 col-lg-8 col-xl-12 product-detail" style={{ margin: '10px 0 10px' }}>
                                                                                            <a> <h6 >{myData.Nom}</h6></a>
                                                                                            <h4 >{myData.price} FCFA</h4>
                                                                                            <ul>
                                                                                                <li>{myData.unit} </li><br />
                                                                                                <li>Ref:{myData.reference}</li><br />
                                                                                                <li>{myData.description}</li><br />
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    <Link type="button" to={`/machines/add-slide/machine/${params}`} className="btn btn-primary ">Affecter un slide</Link>
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Data_slides
                                                            multiSelectOption={true}
                                                            myData={alloc_slides}
                                                            pageSize={10}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    <Link type="button" to={`/maintains/create-maintain/machine/${params}`} className="btn btn-primary " style={{ float: "left" }}>Effectuer une maintenance</Link>
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Data_maintains
                                                            multiSelectOption={true}
                                                            myData={maintenances}
                                                            pageSize={10}
                                                            machine={params}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Data_logs
                                                            multiSelectOption={true}
                                                            myData={logs}
                                                            pageSize={10}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    <Link type="button" to={`/supply/currency/create-currency/machine/${this.props.match.params.id}`} className="btn btn-primary " style={{ float: "left" }}>Approvisionnement en Monnaie</Link>
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Data_currencies
                                                            multiSelectOption={true}
                                                            myData={currencies}
                                                            machine={params}
                                                            pageSize={10}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    <Link type="button" to={`/supply/products/create-product/machine/${this.props.match.params.id}`} className="btn btn-primary " style={{ float: "left" }}>Approvisionnement en Produit</Link>
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Data_products
                                                            multiSelectOption={true}
                                                            myData={products}
                                                            machine={params}
                                                            pageSize={10}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    {/* <Link type="button" to="/machines/list-machine" className="btn btn-primary " style={{float: "left"}}>Ajout de Slide</Link> */}
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Datatable
                                                            multiSelectOption={true}
                                                            myData={orders}
                                                            pageSize={10}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    {/* <Link type="button" to="/machines/list-machine" className="btn btn-primary " style={{float: "left"}}>Ajout de Slide</Link> */}
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Datatable
                                                            multiSelectOption={true}
                                                            myData={paiements}
                                                            pageSize={10}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    {/* <Link type="button" to="/machines/list-machine" className="btn btn-primary " style={{float: "left"}}>Ajout de Slide</Link> */}
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Datatable
                                                            multiSelectOption={true}
                                                            myData={remboursements}
                                                            pageSize={10}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="tab-pane fade show active">
                                                    {/* <Link type="button" to="/machines/list-machine" className="btn btn-primary " style={{float: "left"}}>Ajout de Slide</Link> */}
                                                    <div className="clearfix"></div>
                                                    <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                                                        <Datatable
                                                            multiSelectOption={true}
                                                            myData={transactionoms}
                                                            pageSize={10}
                                                            pagination={true}
                                                            class="-striped -highlight"
                                                        />
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog
                        hidden={this.state.hiddenDialog}
                        onDismiss={() => this.toggleDialog()}
                        dialogContentProps={this.dialogContentProps}
                        isBlocking={true}
                    // modalProps={modalProps}
                    >
                        <DialogFooter>
                            <PrimaryButton onClick={() => this.toggleDialog()} text="Annuler" />
                            <DefaultButton onClick={() => this.handleDeleteProduct()} text="Supprimer" style={{ backgroundColor: '#dc3545', color: '#fff' }} />
                        </DialogFooter>
                    </Dialog>
                </Fragment>
            )

        }

    }
}

const mapStateToProps = (state) => {
    return {
        appromonnaie: state.appromonnaie,
        editmachine: state.editmachine,
        machdetails: state.machdetails,
        roledetails: state.roledetails,
        maintenance: state.maintenance,
        transactionomsbymachine: state.transactionomsbymachine,
        logbymachine: state.logbymachine,
        remboursementsbymachine: state.remboursementsbymachine,
        paiementsbymachine: state.paiementsbymachine,
        maintenancebymachine: state.maintenancebymachine,
        appromonnaiebymachine: state.appromonnaiebymachine,
        approproduitbymachine: state.approproduitbymachine,
        allocproductbymachine: state.allocproductbymachine,

        // allocslidebymachine: state.allocslidebymachine,

        ordersbymachine: state.ordersbymachine
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        transactionomsByMachine: (machine_id) => { dispatch(transactionomActions.transactionomsByMachine(machine_id)) },
        remboursementsByMachine: (machine_id) => { dispatch(remboursementActions.remboursementsByMachine(machine_id)) },
        paiementsByMachine: (machine_id) => { dispatch(paiementActions.paiementsByMachine(machine_id)) },
        ordersByMachine: (machine_id) => { dispatch(orderActions.ordersByMachine(machine_id)) },
        deleteAllocProduct: (productID) => { dispatch(allocproductActions.deleteAllocProduct(productID)) },
        appromonnaies: (machine_id) => { dispatch(appromonnaieActions.appromonnaies(machine_id)) },
        logsByMachine: (machine_id) => { dispatch(logActions.logsByMachine(machine_id)) },
        allocProductByMachine: (machine_id) => { dispatch(allocproductActions.allocProductByMachine(machine_id)) },

        // allocSlideByMachine: (machine_id) => { dispatch(allocslideActions.allocSlideByMachine(machine_id)) },

        maintenanceByMachine: (machine_id) => { dispatch(maintenanceActions.maintenanceByMachine(machine_id)) },
        appromonnaieByMachine: (machine_id) => { dispatch(appromonnaieActions.appromonnaieByMachine(machine_id)) },
        approproduitByMachine: (machine_id) => { dispatch(approproduitActions.approproduitByMachine(machine_id)) },
        machineDetail: (paramID) => { dispatch(machineActions.machineDetail(paramID)) },
        actionsdetailRole: (rolename) => { dispatch(roleActions.actionsdetailRole(rolename)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Detail_machine))
