export const transactionsConstants = {

    ADMIN_TRANSACTIONOM_REQUEST : 'ADMIN_TRANSACTIONOM_REQUEST',
    ADMIN_TRANSACTIONOM_SUCCESS : 'ADMIN_TRANSACTIONOM_SUCCESS',
    ADMIN_TRANSACTIONOM_FAIL : 'ADMIN_TRANSACTIONOM_FAIL',

    ALL_TRANSACTIONOM_REQUEST : 'ALL_TRANSACTIONOM_REQUEST',
    ALL_TRANSACTIONOM_SUCCESS : 'ALL_TRANSACTIONOM_SUCCESS',
    ALL_TRANSACTIONOM_FAIL : 'ALL_TRANSACTIONOM_FAIL',

    NEW_TRANSACTIONOM_REQUEST : 'NEW_TRANSACTIONOM_REQUEST',
    NEW_TRANSACTIONOM_SUCCESS : 'NEW_TRANSACTIONOM_SUCCESS',
    NEW_TRANSACTIONOM_RESET : 'NEW_TRANSACTIONOM_RESET',
    NEW_TRANSACTIONOM_FAIL : 'NEW_TRANSACTIONOM_FAIL',

    DELETE_TRANSACTIONOM_REQUEST : 'DELETE_TRANSACTIONOM_REQUEST',
    DELETE_TRANSACTIONOM_SUCCESS : 'DELETE_TRANSACTIONOM_SUCCESS',
    DELETE_TRANSACTIONOM_RESET : 'DELETE_TRANSACTIONOM_RESET',
    DELETE_TRANSACTIONOM_FAIL : 'DELETE_TRANSACTIONOM_FAIL',

    UPDATE_TRANSACTIONOM_REQUEST : 'UPDATE_TRANSACTIONOM_REQUEST',
    UPDATE_TRANSACTIONOM_SUCCESS : 'UPDATE_TRANSACTIONOM_SUCCESS',
    UPDATE_TRANSACTIONOM_RESET : 'UPDATE_TRANSACTIONOM_RESET',
    UPDATE_TRANSACTIONOM_FAIL : 'UPDATE_TRANSACTIONOM_FAIL',

    TRANSACTIONOM_MACHINE_REQUEST : 'TRANSACTIONOM_MACHINE_REQUEST',
    TRANSACTIONOM_MACHINE_SUCCESS : 'TRANSACTIONOM_MACHINE_SUCCESS',
    TRANSACTIONOM_MACHINE_FAIL : 'TRANSACTIONOM_MACHINE_FAIL',

    TRANSACTIONOM_DETAILS_REQUEST : 'TRANSACTIONOM_DETAILS_REQUEST',
    TRANSACTIONOM_DETAILS_SUCCESS : 'TRANSACTIONOM_DETAILS_SUCCESS',
    TRANSACTIONOM_DETAILS_FAIL : 'TRANSACTIONOM_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS',

}