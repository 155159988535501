export const approproduitConstants = {

    ADMIN_APPROPRODUIT_REQUEST : 'ADMIN_APPROPRODUIT_REQUEST',
    ADMIN_APPROPRODUIT_SUCCESS : 'ADMIN_APPROPRODUIT_SUCCESS',
    ADMIN_APPROPRODUIT_FAIL : 'ADMIN_APPROPRODUIT_FAIL',

    ALL_APPROPRODUIT_REQUEST : 'ALL_APPROPRODUIT_REQUEST',
    ALL_APPROPRODUIT_SUCCESS : 'ALL_APPROPRODUIT_SUCCESS',
    ALL_APPROPRODUIT_FAIL : 'ALL_APPROPRODUIT_FAIL',

    NEW_APPROPRODUIT_REQUEST : 'NEW_APPROPRODUIT_REQUEST',
    NEW_APPROPRODUIT_SUCCESS : 'NEW_APPROPRODUIT_SUCCESS',
    NEW_APPROPRODUIT_RESET : 'NEW_APPROPRODUIT_RESET',
    NEW_APPROPRODUIT_FAIL : 'NEW_APPROPRODUIT_FAIL',

    DELETE_APPROPRODUIT_REQUEST : 'DELETE_APPROPRODUIT_REQUEST',
    DELETE_APPROPRODUIT_SUCCESS : 'DELETE_APPROPRODUIT_SUCCESS',
    DELETE_APPROPRODUIT_RESET : 'DELETE_APPROPRODUIT_RESET',
    DELETE_APPROPRODUIT_FAIL : 'DELETE_APPROPRODUIT_FAIL',

    UPDATE_APPROPRODUIT_REQUEST : 'UPDATE_APPROPRODUIT_REQUEST',
    UPDATE_APPROPRODUIT_SUCCESS : 'UPDATE_APPROPRODUIT_SUCCESS',
    UPDATE_APPROPRODUIT_RESET : 'UPDATE_APPROPRODUIT_RESET',
    UPDATE_APPROPRODUIT_FAIL : 'UPDATE_APPROPRODUIT_FAIL',

    APPROPRODUIT_MACHINE_REQUEST : 'APPROPRODUIT_MACHINE_REQUEST',
    APPROPRODUIT_MACHINE_SUCCESS : 'APPROPRODUIT_MACHINE_SUCCESS',
    APPROPRODUIT_MACHINE_FAIL : 'APPROPRODUIT_MACHINE_FAIL', 

    APPROPRODUIT_DETAILS_REQUEST : 'APPROPRODUIT_DETAILS_REQUEST',
    APPROPRODUIT_DETAILS_SUCCESS : 'APPROPRODUIT_DETAILS_SUCCESS',
    APPROPRODUIT_DETAILS_FAIL : 'APPROPRODUIT_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS',

}