import { remboursementConstants } from "../../constants/remboursementConstants";
import axios from "axios";
import { toast } from 'react-toastify';

export const remboursements = () => {

    return (dispatch) => {
        dispatch({
            type: remboursementConstants.ALL_REMBOURSEMENT_REQUEST
        });

        axios.get('/remboursements')
        .then((response) => {
            // console.log(response.data)
            if(response.data.success === true){
                const { remboursements } = response.data;
                console.log(remboursements)
                const remboursementsCount = response.data.page_meta.total_items_count
                dispatch({
                    type: remboursementConstants.ALL_REMBOURSEMENT_SUCCESS,
                    payload: { remboursements: remboursements, remboursementsCount: remboursementsCount }
                })
                // console.log(remboursements)
            }else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    const error =  response.data.full_messages[0]
                    dispatch({
                        type: remboursementConstants.ALL_REMBOURSEMENT_FAIL,
                        payload: { error: error}
                    });
                    toast.error(error);

                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });
    }
}

export const remboursementsByMachine = (machine) => {
    console.log('machineID', machine)
    return (dispatch) => {
        dispatch({
            type: remboursementConstants.REMBOURSEMENT_MACHINE_REQUEST
        });

        axios.get(`/remboursement/machine/${machine}`)
            .then((response) => {
                console.log('remboursements machine',response);
                if (response.data.success === true) {

                    const { remboursements } = response.data
                    // const remboursementsCount = response.data.page_meta.total_items_count
                    
                    dispatch({
                        type: remboursementConstants.REMBOURSEMENT_MACHINE_SUCCESS,
                        payload: { remboursements: remboursements}
                    });
                } else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);

                        dispatch({
                            type: remboursementConstants.REMBOURSEMENT_MACHINE_FAIL,
                            payload: { error: response.data.full_messages[0] }
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Toto");
            });
    }
}