export const allocproductsConstants = {

    ADMIN_MACHINE_REQUEST : 'ADMIN_MACHINE_REQUEST',
    ADMIN_MACHINE_SUCCESS : 'ADMIN_MACHINE_SUCCESS',
    ADMIN_MACHINE_FAIL : 'ADMIN_MACHINE_FAIL',

    ALL_PRODUCTS_MACHINE_REQUEST : 'ALL_PRODUCTS_MACHINE_REQUEST',
    ALL_PRODUCTS_MACHINE_SUCCESS : 'ALL_PRODUCTS_MACHINE_SUCCESS',
    ALL_PRODUCTS_MACHINE_FAIL : 'ALL_PRODUCTS_MACHINE_FAIL',

    NEW_PRODUCTS_MACHINE_REQUEST : 'NEW_PRODUCTS_MACHINE_REQUEST',
    NEW_PRODUCTS_MACHINE_SUCCESS : 'NEW_PRODUCTS_MACHINE_SUCCESS',
    NEW_PRODUCTS_MACHINE_RESET : 'NEW_PRODUCTS_MACHINE_RESET',
    NEW_PRODUCTS_MACHINE_FAIL : 'NEW_PRODUCTS_MACHINE_FAIL',

    DELETE_PRODUCTS_MACHINE_REQUEST : 'DELETE_PRODUCTS_MACHINE_REQUEST',
    DELETE_PRODUCTS_MACHINE_SUCCESS : 'DELETE_PRODUCTS_MACHINE_SUCCESS',
    DELETE_PRODUCTS_MACHINE_RESET : 'DELETE_PRODUCTS_MACHINE_RESET',
    DELETE_PRODUCTS_MACHINE_FAIL : 'DELETE_PRODUCTS_MACHINE_FAIL',

    UPDATE_PRODUCTS_MACHINE_REQUEST : 'UPDATE_PRODUCTS_MACHINE_REQUEST',
    UPDATE_PRODUCTS_MACHINE_SUCCESS : 'UPDATE_PRODUCTS_MACHINE_SUCCESS',
    UPDATE_PRODUCTS_MACHINE_RESET : 'UPDATE_PRODUCTS_MACHINE_RESET',
    UPDATE_PRODUCTS_MACHINE_FAIL : 'UPDATE_PRODUCTS_MACHINE_FAIL',

    PRODUCTS_MACHINE_REQUEST : 'PRODUCTS_MACHINE_REQUEST',
    PRODUCTS_MACHINE_SUCCESS : 'PRODUCTS_MACHINE_SUCCESS',
    PRODUCTS_MACHINE_FAIL : 'PRODUCTS_MACHINE_FAIL',

    PRODUCTS_MACHINE_DETAILS_REQUEST : 'PRODUCTS_MACHINE_DETAILS_REQUEST',
    PRODUCTS_MACHINE_DETAILS_SUCCESS : 'PRODUCTS_MACHINE_DETAILS_SUCCESS',
    PRODUCTS_MACHINE_DETAILS_FAIL : 'PRODUCTS_MACHINE_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS',
}