import { allocproductsConstants } from "../../constants/allocproductConstants";
import axios from "axios";
import { toast } from 'react-toastify';

export const newAllocProduct = (product) => {
    // console.log()
    // console.log('props', this.props)
    console.log('state', product)
    return (dispatch) => {
        dispatch({
            type: allocproductsConstants.NEW_PRODUCTS_MACHINE_REQUEST
        });
        
        
        let body = {
            machineId: parseInt(product.machine_id),
            productId: product.product_id,
            price: parseInt(product.price),
            quantite: parseInt(product.quantite, 10),
            utilisateurid: parseInt(localStorage.getItem('userid'))
        }
        console.log('body', body)

        const token = localStorage.getItem('token');
        // console.log(token)
        let config = {
            headers: {
              'USER-KEY': `Bearer ${token}`,
              'Content-type': 'application/json'
            }
          }

        // // // console.log( 'asss', token)
        axios.post('/allocproduit', body, config)
        .then((response) => {
            console.log('response alloc',response)
            if(response.data.success === true){
                const {success} = response.data
                // const productCount = response.data.success
                // console.log(product)
                console.log(success)
                dispatch({
                    type: allocproductsConstants.NEW_PRODUCTS_MACHINE_SUCCESS,
                    payload: { success: success }
                })
                toast.success('Ajouter avec succes!!')
            }else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    // const {success} = response.data
                    // toast.error(response.data.full_messages[0]);
                    dispatch({
                        type: allocproductsConstants.NEW_PRODUCTS_MACHINE_FAIL,
                        payload: { error: response.data.full_messages[0] }
                    });
                    for(var i=0; i<response.data.full_messages[i]; i++){
                        toast.error(response.data.full_messages[i])
                    }
                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });

    }
}

export const allocProductByMachine = (machine) => {
    console.log('machineID', machine)
    return (dispatch) => {
        dispatch({
            type: allocproductsConstants.PRODUCTS_MACHINE_REQUEST
        });

        axios.get(`/allocproduit/machine/${machine}`)
            .then((response) => {
                console.log('alloc produits machine',response);
                if (response.data.success === true) {

                    const { alloc_products } = response.data
                    // const maintenancesCount = response.data.page_meta.total_items_count
                    
                    dispatch({
                        type: allocproductsConstants.PRODUCTS_MACHINE_SUCCESS,
                        payload: { alloc_products: alloc_products}
                    });
                } else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);

                        dispatch({
                            type: allocproductsConstants.PRODUCTS_MACHINE_FAIL,
                            payload: { error: response.data.full_messages[0] }
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Toto");
            });
    }
}

export const editAllocProduct = (product) => {
    // console.log()

    return (dispatch) => {
        dispatch({
            type: allocproductsConstants.UPDATE_PRODUCT_REQUEST
        });
        
        // console.log(product.machine_id.value)
        // console.log(product.category_id.value)
        // console.log(product.category_id)
        console.log('pro',product)
        let formData = new FormData();
        formData.append('etat', 1);
        formData.append('status', 1);
        formData.append('name', product.nom);
        formData.append('unit', product.unite);
        formData.append('reference', product.reference);
        formData.append('description', product.description);
        formData.append('category', product.category.label);
        formData.append('images', product.images);
        formData.append('utilisateurid', localStorage.getItem('userid'))

        console.log(formData)

        const token = localStorage.getItem('token');
        // console.log(token)
        let config = {
            headers: {
              'USER-KEY': `Bearer ${token}`,
              'Content-type': 'multipart/form-data'
            }
          }

          // // console.log( 'asss', token)
        axios.put(`/product/${product.id}`, formData, config)
        .then((response) => {
            console.log(response)
            if(response.data.success === true){
                const {success} = response.data

                dispatch({
                    type: allocproductsConstants.UPDATE_PRODUCT_SUCCESS,
                    payload: { isUpdated: success }
                })
                toast.success('Modifier avec succes!!')
            }
            else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    // const {success} = response.data
                    // toast.error(response.data.full_messages[0]);
                    dispatch({
                        type: allocproductsConstants.UPDATE_PRODUCT_FAIL,
                        payload: { error: response.data.errors[0].message }
                    });
                    toast.error(response.data.errors[0].messager)

                    // for(var i=0; i<response.data.full_messages[i]; i++){
                    //     toast.error(response.data.full_messages[i])
                    // }
                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });

    }
}

export const detailAllocProduct = (productId) => {
    console.log('id', productId)
    return (dispatch) => {
        dispatch({
            type: allocproductsConstants.PRODUCT_DETAILS_REQUEST
        });
        // console.log(productId)
        axios.get(`/product/${productId}`)
            .then((response) => {
                console.log('details products',response);
                // if (response.data.success === true) {
                //     const product = response.data
                //     // console.log(product)
                //     dispatch({
                //         type: allocproductsConstants.PRODUCT_DETAILS_SUCCESS,
                //         payload: { product: product }
                //     });
                // } else {
                //     if (response.data.success === false) {
                //         // console.log(response.data.full_messages[0])
                //         toast.error(response.data.full_messages[0]);

                //         dispatch({
                //             type: allocproductsConstants.PRODUCT_DETAILS_FAIL,
                //             payload: { error: response.data.full_messages[0] }
                //         });
                //     }
                // }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });
    }
}

export const deleteAllocProduct = (productID) => {
    // console.log()

    return (dispatch) => {
        dispatch({
            type: allocproductsConstants.DELETE_PRODUCTS_MACHINE_REQUEST
        });

        const token = localStorage.getItem('token');
        let config = {
            headers: {
              'USER-KEY': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }

        // console.log(productID)
        axios.delete(`/allocproduit/${productID}`, config)
        .then((response) => {
            console.log(response)
            if(response.data.success === true){
                const {success} = response.data
                    console.log(success)

                // axios.get('products')
                //     .then((response))
                dispatch({
                    type: allocproductsConstants.DELETE_PRODUCTS_MACHINE_SUCCESS,
                    payload: {isDeleted: success}
                })
            }else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    toast.error(response.data.full_messages[0]);
                    dispatch({
                        type: allocproductsConstants.DELETE_PRODUCTS_MACHINE_FAIL,
                        payload: { error: response.data.full_messages[0] }
                    });
                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });

    }
}
