 export const appromonnaieConstants = {

   ADMIN_APPROMONNAIE_REQUEST : 'ADMIN_APPROMONNAIE_REQUEST',
   ADMIN_APPROMONNAIE_SUCCESS : 'ADMIN_APPROMONNAIE_SUCCESS',
   ADMIN_APPROMONNAIE_FAIL : 'ADMIN_APPROMONNAIE_FAIL',

   ALL_APPROMONNAIE_REQUEST : 'ALL_APPROMONNAIE_REQUEST',
   ALL_APPROMONNAIE_SUCCESS : 'ALL_APPROMONNAIE_SUCCESS',
   ALL_APPROMONNAIE_FAIL : 'ALL_APPROMONNAIE_FAIL',

   NEW_APPROMONNAIE_REQUEST : 'NEW_APPROMONNAIE_REQUEST',
   NEW_APPROMONNAIE_SUCCESS : 'NEW_APPROMONNAIE_SUCCESS',
   NEW_APPROMONNAIE_RESET : 'NEW_APPROMONNAIE_RESET',
   NEW_APPROMONNAIE_FAIL : 'NEW_APPROMONNAIE_FAIL',

   DELETE_APPROMONNAIE_REQUEST : 'DELETE_APPROMONNAIE_REQUEST',
   DELETE_APPROMONNAIE_SUCCESS : 'DELETE_APPROMONNAIE_SUCCESS',
   DELETE_APPROMONNAIE_RESET : 'DELETE_APPROMONNAIE_RESET',
   DELETE_APPROMONNAIE_FAIL : 'DELETE_APPROMONNAIE_FAIL',

   UPDATE_APPROMONNAIE_REQUEST : 'UPDATE_APPROMONNAIE_REQUEST',
   UPDATE_APPROMONNAIE_SUCCESS : 'UPDATE_APPROMONNAIE_SUCCESS',
   UPDATE_APPROMONNAIE_RESET : 'UPDATE_APPROMONNAIE_RESET',
   UPDATE_APPROMONNAIE_FAIL : 'UPDATE_APPROMONNAIE_FAIL',

   APPROMONNAIE_MACHINE_REQUEST : 'APPROMONNAIE_MACHINE_REQUEST',
   APPROMONNAIE_MACHINE_SUCCESS : 'APPROMONNAIE_MACHINE_SUCCESS',
   APPROMONNAIE_MACHINE_FAIL : 'APPROMONNAIE_MACHINE_FAIL',

   APPROMONNAIE_DETAILS_REQUEST : 'APPROMONNAIE_DETAILS_REQUEST',
   APPROMONNAIE_DETAILS_SUCCESS : 'APPROMONNAIE_DETAILS_SUCCESS',
   APPROMONNAIE_DETAILS_FAIL : 'APPROMONNAIE_DETAILS_FAIL',

   CLEAR_ERRORS : 'CLEAR_ERRORS',

 }