export const allocslidesConstants = {

    ADMIN_MACHINE_REQUEST : 'ADMIN_MACHINE_REQUEST',
    ADMIN_MACHINE_SUCCESS : 'ADMIN_MACHINE_SUCCESS',
    ADMIN_MACHINE_FAIL : 'ADMIN_MACHINE_FAIL',

    ALL_SLIDES_MACHINE_REQUEST : 'ALL_SLIDES_MACHINE_REQUEST',
    ALL_SLIDES_MACHINE_SUCCESS : 'ALL_SLIDES_MACHINE_SUCCESS',
    ALL_SLIDES_MACHINE_FAIL : 'ALL_SLIDES_MACHINE_FAIL',

    NEW_SLIDES_MACHINE_REQUEST : 'NEW_SLIDES_MACHINE_REQUEST',
    NEW_SLIDES_MACHINE_SUCCESS : 'NEW_SLIDES_MACHINE_SUCCESS',
    NEW_SLIDES_MACHINE_RESET : 'NEW_SLIDES_MACHINE_RESET',
    NEW_SLIDES_MACHINE_FAIL : 'NEW_SLIDES_MACHINE_FAIL',

    DELETE_SLIDES_MACHINE_REQUEST : 'DELETE_SLIDES_MACHINE_REQUEST',
    DELETE_SLIDES_MACHINE_SUCCESS : 'DELETE_SLIDES_MACHINE_SUCCESS',
    DELETE_SLIDES_MACHINE_RESET : 'DELETE_SLIDES_MACHINE_RESET',
    DELETE_SLIDES_MACHINE_FAIL : 'DELETE_SLIDES_MACHINE_FAIL',

    UPDATE_SLIDES_MACHINE_REQUEST : 'UPDATE_SLIDES_MACHINE_REQUEST',
    UPDATE_SLIDES_MACHINE_SUCCESS : 'UPDATE_SLIDES_MACHINE_SUCCESS',
    UPDATE_SLIDES_MACHINE_RESET : 'UPDATE_SLIDES_MACHINE_RESET',
    UPDATE_SLIDES_MACHINE_FAIL : 'UPDATE_SLIDES_MACHINE_FAIL',

    SLIDES_MACHINE_REQUEST : 'SLIDES_MACHINE_REQUEST',
    SLIDES_MACHINE_SUCCESS : 'SLIDES_MACHINE_SUCCESS',
    SLIDES_MACHINE_FAIL : 'SLIDES_MACHINE_FAIL',

    SLIDES_MACHINE_DETAILS_REQUEST : 'SLIDES_MACHINE_DETAILS_REQUEST',
    SLIDES_MACHINE_DETAILS_SUCCESS : 'SLIDES_MACHINE_DETAILS_SUCCESS',
    SLIDES_MACHINE_DETAILS_FAIL : 'SLIDES_MACHINE_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS',
}
