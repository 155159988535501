export const pieceConstants = {

    ADMIN_PIECE_REQUEST : 'ADMIN_PIECE_REQUEST',
    ADMIN_PIECE_SUCCESS : 'ADMIN_PIECE_SUCCESS',
    ADMIN_PIECE_FAIL : 'ADMIN_PIECE_FAIL',

    ALL_PIECE_REQUEST : 'ALL_PIECE_REQUEST',
    ALL_PIECE_SUCCESS : 'ALL_PIECE_SUCCESS',
    ALL_PIECE_FAIL : 'ALL_PIECE_FAIL',

    NEW_PIECE_REQUEST : 'NEW_PIECE_REQUEST',
    NEW_PIECE_SUCCESS : 'NEW_PIECE_SUCCESS',
    NEW_PIECE_RESET : 'NEW_PIECE_RESET',
    NEW_PIECE_FAIL : 'NEW_PIECE_FAIL',

    DELETE_PIECE_REQUEST : 'DELETE_PIECE_REQUEST',
    DELETE_PIECE_SUCCESS : 'DELETE_PIECE_SUCCESS',
    DELETE_PIECE_RESET : 'DELETE_PIECE_RESET',
    DELETE_PIECE_FAIL : 'DELETE_PIECE_FAIL',

    UPDATE_PIECE_REQUEST : 'UPDATE_PIECE_REQUEST',
    UPDATE_PIECE_SUCCESS : 'UPDATE_PIECE_SUCCESS',
    UPDATE_PIECE_RESET : 'UPDATE_PIECE_RESET',
    UPDATE_PIECE_FAIL : 'UPDATE_PIECE_FAIL',

    PIECE_DETAILS_REQUEST : 'PIECE_DETAILS_REQUEST',
    PIECE_DETAILS_SUCCESS : 'PIECE_DETAILS_SUCCESS',
    PIECE_DETAILS_FAIL : 'PIECE_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS'
}