import React, { Component,Fragment } from 'react'
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
// import CKEditors from 'react-ckeditor-component';
import { withRouter, Redirect, Router } from 'react-router-dom';
import * as machineActions from "../../../redux/actions/machineActions";
import * as allocproductActions from "../../../redux/actions/allocproductActions";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import * as approproduitActions from '../../../redux/actions/approproduitActions';
import Select from 'react-select';

export class Tabset_product extends Component {
    constructor(props) {
        super(props); 


        this.state = {
            isLoading: false,
            open: false,
            quantite: '',
            description: '',
            alloc_product_id: '',
            AllOptions: [],
            machine_id: '',
            machine: this.props.machineId
            
        };

        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmitChange = this.handleSubmitChange.bind(this)
        // this.handleCkeditorChange = this.handleCkeditorChange.bind(this)

       
    }

    componentDidMount = () => {
        // console.log('props', this.props)
        let options = []
        let listAllocProducts = []

        this.props.allocProductByMachine(this.props.machineId);

        setTimeout(() => {
            // console.log(this.props.allocproductbymachine.alloc_products)

            this.props.allocproductbymachine.alloc_products.map(alloc_product => {
                // console.log('i',product.id)
                let item = {
                    id: alloc_product.product.product_id,
                    Nom: alloc_product.product.name
                }
                listAllocProducts.push(item);
            })
            
            console.log('maaa',listAllocProducts)
            // // console.log(lis)
            listAllocProducts.map(listAllocProduct => {
                let option = 
                    {value:listAllocProduct.id, label: listAllocProduct.Nom }
                
                // console.log(option)
                options.push(option)
            })
            console.log('11111',options)
          
            this.setState({
                AllOptions: options
            })
        }, 1000)
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handleChange = (alloc_product_id) => {
        this.setState({alloc_product_id});
    }

    handleSubmitChange = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })        
        // console.log(this.state.AllOptions)
        this.props.newApproproduits(this.state)
       
        setTimeout(()=> {
            // console.log('addapproproduit',this.props.addapproproduit)
            if(this.props.addapproproduit.success === true){
                this.props.history.push('/machines/machine-details/'+this.props.machineId);
            }else{
                this.props.history.push('/supply/products/create-product/machine/'+this.props.machineId);
                this.setState({
                    isLoading: false
                })
            }
        }, 1000)

    }
    
    render() {
        const {AllOptions, machine, quantite, alloc_product_id, description, isLoading} =this.state
        // console.log("looo", AllOptions)
        const customStyles = {
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                width: '715px'
            }),
        }
        
        return (
            <Fragment>
                <form className="needs-validation product-add" noValidate="">
                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4"> Quantité</label>
                        <input className="form-control col-xl-8 col-md-7"
                            name="quantite" 
                            value={quantite}
                            onChange={this.handleInputChange}
                            id="validationCustom1" type="number" 
                            required="" 
                        />
                    </div>
                    <div className="form-group row">
                        <label className="col-xl-3 col-md-4" >Nom de produit :</label>
                        <div className="col-xl-8 col-md-7">
                            <Select
                                name="alloc_product_id"
                                styles={customStyles} 
                                value={alloc_product_id}
                                options={AllOptions}
                                onChange={this.handleChange}
                                required="" 
                            />
                        </div>
                    </div>
                    {/* <div className="form-group row">
                        <label className="col-xl-3 col-md-4" >Nom de machine :</label>
                        <Select
                            name="machine_id"
                            styles={customStyles} 
                            value={machine}
                            onChange={this.handleChange}
                            required="" 
                        />
                    </div> */}
                    <div className="form">
                        <div className="form-group row">
                            <label className="col-xl-3 col-sm-4">Description :</label>
                            <textarea className="form-control col-xl-8 col-md-7"
                                name="description" 
                                value={description} 
                                onChange={this.handleInputChange}
                                rows="8" cols="92"
                            />
                        </div>
                    </div>
                    <ToastContainer />
                    <div className="offset-xl-3 offset-sm-4">
                        <button type="button" className="btn btn-primary"
                            onClick={this.handleSubmitChange}
                            disabled={isLoading}
                        >
                        Enregister</button>
                    </div>
                </form>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        allocproductbymachine: state.allocproductbymachine,
        product: state.product,
        addapproproduit: state.addapproproduit,
        machdetails: state.machdetails,
    }
}
const mapDispatchToProps = (dispatch) =>{
    return {
        allocProductByMachine: (machine_id) => {dispatch(allocproductActions.allocProductByMachine(machine_id))},
        newApproproduits: (approproduit) => {dispatch(approproduitActions.newApproproduits(approproduit))},
        machineDetail: (paramID) => {dispatch( machineActions.machineDetail(paramID))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tabset_product))
