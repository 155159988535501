import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import * as productActions from '../../redux/actions/productActions';
import * as allocproductActions from "../../redux/actions/allocproductActions";
import styles from '../../assets/scss/FormErrors.module.css'


const AddAllocProductForm = (props) => {
  const initialValues = {
    machine_id: 0,
    product_id: 0,
    price: 0,
    quantite: 1,
  }

  const onSubmit = values => {
    values.machine_id = props.machine_id
    values.product_id = props.product_id
    console.log({ values })

    props.newAllocProduct(values)

    window.location.reload()
    // props.history.push('/machines/machine-details/' + props.match.params.id)
    // props.history.push('/machines/add-product/machine/' + props.match.params.id);


    setTimeout(() => {
      // console.log(props.addproductinmachine.success)
      // if (props.addproductinmachine.success === true) {
      //   props.history.push('/machines/machine-details/' + props.match.params.id);
      // } else {
      //   props.history.push('/machines/add-product/machine/' + props.match.params.id);
      // }
    }, 1000)
  }

  const validationSchema = Yup.object({
    machine_id: Yup.number().required('Product not found'),
    product_id: Yup.number().required('Machine not found'),
    price: Yup.number().required('Required').positive('Le prix doit être supérieur à zero'),
    quantite: Yup.number().required('Required').positive('Le quantité doit être supérieur à zero').integer('Le quantité ne peut pas être décimale')
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form>
        <div className="form-group mb-3 row">
          <label htmlFor='price' className="col-12 mb-0">Prix :</label>
          <div className="col-12">
            <Field
              type="number"
              id='price'
              name="price"
              style={{ width: '100%' }}
            />
            <span style={{ width: '100%', fontWeight: 'bold' }}>FCFA</span>
            <div className={styles.error}>
              <ErrorMessage name='price' />
            </div>
          </div>
        </div>
        <div className="form-group mb-3 row">
          <label htmlFor='quantite' className="col-12 mb-0">Quantité :</label>
          <div className="col-12">
            <Field
              type="number"
              id='quantite'
              name="quantite"
              style={{ width: '100%' }}
            />
            <span style={{ width: '100%', fontWeight: 'bold' }}>FCFA</span>
            <div className={styles.error}>
              <ErrorMessage name='quantite' />
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary mr-2"
        >Ajouter</button>
      </Form>
    </Formik>
  )
}

const mapStateToProps = (state) => {
  return {
    addproductinmachine: state.addproductinmachine,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    newAllocProduct: (product) => { dispatch(allocproductActions.newAllocProduct(product)) },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddAllocProductForm))
