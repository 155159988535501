export const roleConstants = {

    ALL_ROLES_REQUEST : 'ALL_ROLES_REQUEST',
    ALL_ROLES_SUCCESS : 'ALL_ROLES_SUCCESS',
    ALL_ROLES_FAIL : 'ALL_ROLES_FAIL',
    
    NEW_ROLES_REQUEST : 'NEW_ROLES_REQUEST',
    NEW_ROLES_SUCCESS : 'NEW_ROLES_SUCCESS',
    NEW_ROLES_FAIL : 'NEW_ROLES_FAIL',
    
    UPDATE_ROLES_REQUEST : 'UPDATE_ROLES_REQUEST',
    UPDATE_ROLES_RESET : 'UPDATE_ROLES_RESET',
    UPDATE_ROLES_SUCCESS : 'UPDATE_ROLES_SUCCESS',
    UPDATE_ROLES_FAIL : 'UPDATE_ROLES_FAIL',

    DELETE_ROLES_REQUEST : 'DELETE_ROLES_REQUEST',
    DELETE_ROLES_RESET : 'DELETE_ROLES_RESET',
    DELETE_ROLES_SUCCESS : 'DELETE_ROLES_SUCCESS',
    DELETE_ROLES_FAIL : 'DELETE_ROLES_FAIL',
    
    ROLE_DETAILS_REQUEST : 'ROLE_DETAILS_REQUEST',
    ROLE_DETAILS_SUCCESS : 'ROLE_DETAILS_SUCCESS',
    ROLE_DETAILS_FAIL : 'ROLE_DETAILS_FAIL',

    ROLE_PRIVATE_REQUEST : 'ROLE_PRIVATE_REQUEST',
    ROLE_PRIVATE_SUCCESS : 'ROLE_PRIVATE_SUCCESS',
    ROLE_PRIVATE_FAIL : 'ROLE_PRIVATE_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS'
}