import React, { Component,Fragment } from 'react'
import Breadcrumb from '../../common/breadcrumb';
import Tabset_currency from './tabset-currency';
import { Link } from "react-router-dom";
import {connect} from "react-redux";
import * as roleActions  from "../../../redux/actions/roleActions";
import BeatLoader from "react-spinners/BeatLoader";
import * as appromonnaieActions from '../../../redux/actions/appromonnaieActions';
import * as machineActions from "../../../redux/actions/machineActions";
import { withRouter, Redirect, Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

export class Create_currency extends Component {
    constructor(props){
        super(props)

        this.state = {
            visible: false,
            roles: null,
            loading: false,
            ajouter: null,
            lister: null,
            id: null,
            quantite: '',
            description: '',
            machine_id:  '',
            AllOptions: [],
            machine: this.props.match.params.id,
            params: null
        }

    }

    componentDidMount = () => {
        let ajout = null;
        let liste = null;

        this.setState({
            loading: true
        })

        this.props.actionsdetailRole(localStorage.getItem('roles'))

        setTimeout(() => {
            this.props.roledetails.role.map(rl => {
                console.log('rl',rl)
                ajout = rl.ajouterApproMonnaie
                liste = rl.listeApproMonnaie
            })
            this.setState({
                ajouter: ajout,
                lister: liste
            })
            console.log('rls', this.state.roles)
            if(this.state.lister == '1'){
                this.setState({
                    visible: true
                })
            }
            this.setState({
                loading: false
            })
        }, 1000)
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    
    handleChange = (machine_id) => {
        this.setState({machine_id});        
    }

    handleSubmitChange = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
            params: this.props.match.params.id
        })

        // console.log(this.state.AllOptions)
        this.props.newAppromonnaies(this.state);
        setTimeout(()=> {
            // console.log(this.props.addappromonnaie)
            if(this.props.addappromonnaie.success === true){
                this.props.history.push('/machines/machine-details/'+this.state.params);
            }else{
                this.props.history.push('/supply/currency/create-currency/machine/'+this.state.params);
                this.setState({
                    isLoading: false
                })
            }
        }, 1000)
        

    }
    
    render() {
        const {ajouter, loading, roles, visible, isLoading, AllOptions, quantite, description, machine, machine_id} = this.state
        const customStyles = {
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                width: '715px'
            }),
        }
        if(loading){
            return(
                <div style={{display: "flex", justifyContent: "center", 
                            alignItems: "center", width: "100%", height: "100vh"}}>
                   <BeatLoader color={"#EC1C5B"} loading={loading} size={50} />
                </div>
            )
        }else{
            if(ajouter == '1'){
                return (
                    <Fragment>
                        <Breadcrumb title="Approvisionnement de Monnaie" parent="Approvisionnement / Monnaie" />
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                    {visible === true ?
                                        (
                                            <div className="card-header">
                                                <Link type="button" to={`/machines/machine-details/${this.props.match.params.id}`} 
                                                className="btn btn-primary">Retour</Link>
                                            </div>
                                        ):
                                        null
                                    }
                                        <div className="card-body">
                                            <form className="needs-validation">
                                                <div className="form-group row">
                                                    <label className="col-xl-3 col-md-4"> Montant (FCFA)</label>
                                                    <input className="form-control col-xl-8 col-md-7"
                                                    name="quantite" 
                                                    value={quantite}
                                                    onChange={this.handleInputChange}
                                                    id="validationCustom1" type="number" 
                                                    required="" />
                                                </div>
                                                {/* <div className="form-group row">
                                                    <label className="col-xl-3 col-md-4" >Nom de machine :</label>
                                                    <div className="col-xl-8 col-md-7">
                                                        <Select
                                                            name="machine_id"
                                                            styles={customStyles} 
                                                            value={machine}
                                                            // options={AllOptions}
                                                            onChange={this.handleChange}
                                                            required="" 
                                                        />
                                                    </div>
                                                </div> */}
                                                <div className="form">
                                                    <div className="form-group row">
                                                        <label className="col-xl-3 col-sm-4">Description :</label>                              
                                                        <textarea className="form-control col-xl-8 col-md-7" 
                                                            name="description" 
                                                            value={description} 
                                                            onChange={this.handleInputChange}
                                                            rows="8" cols="92"
                                                        />
                                                    </div>
                                                </div>
                                                <ToastContainer />
                                                <div className="offset-xl-3 offset-sm-4">
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={this.handleSubmitChange}
                                                        disabled={isLoading}
                                                    >
                                                    Enregister</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            }else{
                return(
                    <Fragment>

                    </Fragment>
                )
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        roledetails: state.roledetails,
        machine: state.machine,
        addappromonnaie: state.addappromonnaie,
        machdetails: state.machdetails,
    }
}
const mapDispatchToProps = (dispatch) =>{
    return {
        actionsdetailRole: (rolename) => {dispatch(roleActions.actionsdetailRole(rolename))},
        appromonnaies: () => {dispatch(appromonnaieActions.appromonnaies())},
        machines: () => {dispatch( machineActions.machines())},
        newAppromonnaies: (appromonnaie) => {dispatch(appromonnaieActions.newAppromonnaies(appromonnaie))},
        machineDetail: (paramID) => {dispatch( machineActions.machineDetail(paramID))},
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Create_currency)
