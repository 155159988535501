export const logsConstants = {
    ADMIN_LOGS_REQUEST : 'ADMIN_LOGS_REQUEST',
    ADMIN_LOGS_SUCCESS : 'ADMIN_LOGS_SUCCESS',
    ADMIN_LOGS_FAIL : 'ADMIN_LOGS_FAIL',

    ALL_LOGS_REQUEST : 'ALL_LOGS_REQUEST',
    ALL_LOGS_SUCCESS : 'ALL_LOGS_SUCCESS',
    ALL_LOGS_FAIL : 'ALL_LOGS_FAIL',

    NEW_LOGS_REQUEST : 'NEW_LOGS_REQUEST',
    NEW_LOGS_SUCCESS : 'NEW_LOGS_SUCCESS',
    NEW_LOGS_RESET : 'NEW_LOGS_RESET',
    NEW_LOGS_FAIL : 'NEW_LOGS_FAIL',

    DELETE_LOGS_REQUEST : 'DELETE_LOGS_REQUEST',
    DELETE_LOGS_SUCCESS : 'DELETE_LOGS_SUCCESS',
    DELETE_LOGS_RESET : 'DELETE_LOGS_RESET',
    DELETE_LOGS_FAIL : 'DELETE_LOGS_FAIL',

    UPDATE_LOGS_REQUEST : 'UPDATE_LOGS_REQUEST',
    UPDATE_LOGS_SUCCESS : 'UPDATE_LOGS_SUCCESS',
    UPDATE_LOGS_RESET : 'UPDATE_LOGS_RESET',
    UPDATE_LOGS_FAIL : 'UPDATE_LOGS_FAIL',

    LOGS_MACHINE_REQUEST : 'LOGS_MACHINE_REQUEST',
    LOGS_MACHINE_SUCCESS : 'LOGS_MACHINE_SUCCESS',
    LOGS_MACHINE_FAIL : 'LOGS_MACHINE_FAIL',

    LOGS_DETAILS_REQUEST : 'LOGS_DETAILS_REQUEST',
    LOGS_DETAILS_SUCCESS : 'LOGS_DETAILS_SUCCESS',
    LOGS_DETAILS_FAIL : 'LOGS_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS',
}