import { allocslidesConstants } from "../../constants/allocslideConstants";
import axios from "axios";
import { toast } from 'react-toastify';

export const newAllocSlide = (slide) => {
    // console.log()
    // console.log('props', this.props)
    console.log('state', slide)
    return (dispatch) => {
        dispatch({
            type: allocslidesConstants.NEW_SLIDES_MACHINE_REQUEST
        });
        
        
        let body = {
            machineId: parseInt(slide.machine_id),
            slideId: slide.slide_id,
            utilisateurid: parseInt(localStorage.getItem('userid'))
        }
        console.log('body', body)

        const token = localStorage.getItem('token');
        // console.log(token)
        let config = {
            headers: {
              'USER-KEY': `Bearer ${token}`,
              'Content-type': 'application/json'
            }
          }

        // // // console.log( 'asss', token)
        axios.post('/allocslide', body, config)
        .then((response) => {
            console.log('response allocslide',response)
            if(response.data.success === true){
                const {success} = response.data
                // const slideCount = response.data.success
                // console.log(slide)
                console.log(success)
                dispatch({
                    type: allocslidesConstants.NEW_SLIDES_MACHINE_SUCCESS,
                    payload: { success: success }
                })
                toast.success('Ajouter avec succes!!')
            }else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    // const {success} = response.data
                    // toast.error(response.data.full_messages[0]);
                    dispatch({
                        type: allocslidesConstants.NEW_SLIDES_MACHINE_FAIL,
                        payload: { error: response.data.full_messages[0] }
                    });
                    for(var i=0; i<response.data.full_messages[i]; i++){
                        toast.error(response.data.full_messages[i])
                    }
                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });

    }
}

export const allocSlideByMachine = (machine) => {
    console.log('machineID', machine)
    return (dispatch) => {
        dispatch({
            type: allocslidesConstants.SLIDES_MACHINE_REQUEST
        });

        axios.get(`/allocproduit/machine/${machine}`)
            .then((response) => {
                console.log('alloc produits machine',response);
                if (response.data.success === true) {

                    const { alloc_slides } = response.data
                    // const maintenancesCount = response.data.page_meta.total_items_count
                    
                    dispatch({
                        type: allocslidesConstants.SLIDES_MACHINE_SUCCESS,
                        payload: { alloc_slides: alloc_slides}
                    });
                } else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);

                        dispatch({
                            type: allocslidesConstants.SLIDES_MACHINE_FAIL,
                            payload: { error: response.data.full_messages[0] }
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Toto");
            });
    }
}

export const editAllocSlide = (slide) => {
    // console.log()

    return (dispatch) => {
        dispatch({
            type: allocslidesConstants.UPDATE_SLIDE_REQUEST
        });
        
        // console.log(slide.machine_id.value)
        // console.log(slide.category_id.value)
        // console.log(slide.category_id)
        console.log('slide to edit',slide)
        let formData = new FormData();
        formData.append('etat', 1);
        formData.append('status', 1);
        formData.append('name', slide.nom);
        formData.append('unit', slide.unite);
        formData.append('reference', slide.reference);
        formData.append('description', slide.description);
        formData.append('category', slide.category.label);
        formData.append('images', slide.images);
        formData.append('utilisateurid', localStorage.getItem('userid'))

        console.log(formData)

        const token = localStorage.getItem('token');
        // console.log(token)
        let config = {
            headers: {
              'USER-KEY': `Bearer ${token}`,
              'Content-type': 'multipart/form-data'
            }
          }

          // // console.log( 'asss', token)
        axios.put(`/slide/${slide.id}`, formData, config)
        .then((response) => {
            console.log(response)
            if(response.data.success === true){
                const {success} = response.data

                dispatch({
                    type: allocslidesConstants.UPDATE_SLIDE_SUCCESS,
                    payload: { isUpdated: success }
                })
                toast.success('Modifier avec succes!!')
            }
            else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    // const {success} = response.data
                    // toast.error(response.data.full_messages[0]);
                    dispatch({
                        type: allocslidesConstants.UPDATE_SLIDE_FAIL,
                        payload: { error: response.data.errors[0].message }
                    });
                    toast.error(response.data.errors[0].messager)

                    // for(var i=0; i<response.data.full_messages[i]; i++){
                    //     toast.error(response.data.full_messages[i])
                    // }
                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });

    }
}

export const detailAllocSlide = (slideId) => {
    console.log('id', slideId)
    return (dispatch) => {
        dispatch({
            type: allocslidesConstants.SLIDE_DETAILS_REQUEST
        });
        // console.log(slideId)
        axios.get(`/slide/${slideId}`)
            .then((response) => {
                console.log('details slides',response);
                // if (response.data.success === true) {
                //     const slide = response.data
                //     // console.log(slide)
                //     dispatch({
                //         type: allocslidesConstants.SLIDE_DETAILS_SUCCESS,
                //         payload: { slide: slide }
                //     });
                // } else {
                //     if (response.data.success === false) {
                //         // console.log(response.data.full_messages[0])
                //         toast.error(response.data.full_messages[0]);

                //         dispatch({
                //             type: allocslidesConstants.SLIDE_DETAILS_FAIL,
                //             payload: { error: response.data.full_messages[0] }
                //         });
                //     }
                // }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });
    }
}

export const deleteAllocSlide = (slideID) => {
    // console.log()

    return (dispatch) => {
        dispatch({
            type: allocslidesConstants.DELETE_SLIDES_MACHINE_REQUEST
        });

        const token = localStorage.getItem('token');
        let config = {
            headers: {
              'USER-KEY': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          }

        // console.log(slideID)
        axios.delete(`/allocproduit/${slideID}`, config)
        .then((response) => {
            console.log(response)
            if(response.data.success === true){
                const {success} = response.data
                    console.log(success)

                // axios.get('slides')
                //     .then((response))
                dispatch({
                    type: allocslidesConstants.DELETE_SLIDES_MACHINE_SUCCESS,
                    payload: {isDeleted: success}
                })
            }else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    toast.error(response.data.full_messages[0]);
                    dispatch({
                        type: allocslidesConstants.DELETE_SLIDES_MACHINE_FAIL,
                        payload: { error: response.data.full_messages[0] }
                    });
                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });

    }
}
