export const productConstants = {
    
    ADMIN_PRODUCTS_REQUEST : 'ADMIN_PRODUCTS_REQUEST',
    ADMIN_PRODUCTS_SUCCESS : 'ADMIN_PRODUCTS_SUCCESS',
    ADMIN_PRODUCTS_FAIL : 'ADMIN_PRODUCTS_FAIL',

    ALL_PRODUCTS_REQUEST : 'ALL_PRODUCTS_REQUEST',
    ALL_PRODUCTS_SUCCESS : 'ALL_PRODUCTS_SUCCESS',
    ALL_PRODUCTS_FAIL : 'ALL_PRODUCTS_FAIL',

    NEW_PRODUCT_REQUEST : 'NEW_PRODUCT_REQUEST',
    NEW_PRODUCT_SUCCESS : 'NEW_PRODUCT_SUCCESS',
    NEW_PRODUCT_RESET : 'NEW_PRODUCT_RESET',
    NEW_PRODUCT_FAIL : 'NEW_PRODUCT_FAIL',

    DELETE_PRODUCT_REQUEST : 'DELETE_PRODUCT_REQUEST',
    DELETE_PRODUCT_SUCCESS : 'DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_RESET : 'DELETE_PRODUCT_RESET',
    DELETE_PRODUCT_FAIL : 'DELETE_PRODUCT_FAIL',

    UPDATE_PRODUCT_REQUEST : 'UPDATE_PRODUCT_REQUEST',
    UPDATE_PRODUCT_SUCCESS : 'UPDATE_PRODUCT_SUCCESS',
    UPDATE_PRODUCT_RESET : 'UPDATE_PRODUCT_RESET',
    UPDATE_PRODUCT_FAIL : 'UPDATE_PRODUCT_FAIL',

    PRODUCT_DETAILS_REQUEST : 'PRODUCT_DETAILS_REQUEST',
    PRODUCT_DETAILS_SUCCESS : 'PRODUCT_DETAILS_SUCCESS',
    PRODUCT_DETAILS_FAIL : 'PRODUCT_DETAILS_FAIL',

    DEACTIVATE_PRODUCT_REQUEST : 'DEACTIVATE_PRODUCT_REQUEST',
    DEACTIVATE_PRODUCT_SUCCESS : 'DEACTIVATE_PRODUCT_SUCCESS',
    DEACTIVATE_PRODUCT_FAIL : 'DEACTIVATE_PRODUCT_FAIL',

    ACTIVATE_PRODUCT_REQUEST : 'ACTIVATE_PRODUCT_REQUEST',
    ACTIVATE_PRODUCT_SUCCESS : 'ACTIVATE_PRODUCT_SUCCESS',
    ACTIVATE_PRODUCT_FAIL : 'ACTIVATE_PRODUCT_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS'

}
