import { userConstants } from "../../constants/userConstants"; 

export const authReducer = (state = { user: {}, token: null, roles:{}, isAuthenticated: false, isRegistered: true }, action) => {
    console.log(action.type);
    switch (action.type) {

        case userConstants.REGISTER_USER_REQUEST:
            return {
                loading: true,
                isRegistered: false
            }
            
        case userConstants.LOGIN_REQUEST:
        case userConstants.LOAD_USER_REQUEST:
            return {
                loading: true,
                isAuthenticated: false,
            } 

        case userConstants.LOGIN_SUCCESS:
        case userConstants.LOAD_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload.user.username,
                token: action.payload.token,
                roles: action.payload.roles
            }

        case userConstants.REGISTER_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isRegistered: true,
                
            }

        case userConstants.LOGOUT_SUCCESS:
            return {
                loading: false,
                isAuthenticated: false,
                user: null
            }

        case userConstants.LOAD_USER_FAIL:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
                error: action.payload
            }

        case userConstants.LOGOUT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case userConstants.LOGIN_FAIL:
        case userConstants.REGISTER_USER_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                message: action.payload
            }

        case userConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const userReducer = (state = {}, action) => {
    switch (action.type) {

        case userConstants.UPDATE_PROFILE_REQUEST:
        case userConstants.UPDATE_PASSWORD_REQUEST:
        case userConstants.UPDATE_USER_REQUEST:
        case userConstants.DELETE_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userConstants.UPDATE_PROFILE_SUCCESS:
        case userConstants.UPDATE_PASSWORD_SUCCESS:
        case userConstants.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }

        case userConstants.DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }

        case userConstants.UPDATE_PROFILE_RESET:
        case userConstants.UPDATE_PASSWORD_RESET:
        case userConstants.UPDATE_USER_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case userConstants.DELETE_USER_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case userConstants.UPDATE_PROFILE_FAIL:
        case userConstants.UPDATE_PASSWORD_FAIL:
        case userConstants.UPDATE_USER_FAIL:
        case userConstants.DELETE_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case userConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const forgotPasswordReducer = (state = {}, action) => {
    switch (action.type) {

        case userConstants.FORGOT_PASSWORD_REQUEST:
        case userConstants.NEW_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            }

        case userConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload
            }

        case userConstants.NEW_PASSWORD_SUCCESS:
            return {
                ...state,
                success: action.payload
            }

        case userConstants.FORGOT_PASSWORD_FAIL:
        case userConstants.NEW_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case userConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const newUsersReducer = (state = { users: [] }, action) => {
    switch (action.type) {

        case userConstants.NEW_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userConstants.NEW_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.success,
            }

        case userConstants.NEW_USERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case userConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const allUsersReducer = (state = { users: [] }, action) => {
    switch (action.type) {

        case userConstants.ALL_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userConstants.ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.users
            }

        case userConstants.ALL_USERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case userConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const userDetailsReducer = (state = { user: {} }, action) => {
    switch (action.type) {

        case userConstants.USER_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case userConstants.USER_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.user
            }

        case userConstants.USER_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case userConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}