import React, { Component,Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import { Link } from "react-router-dom";
import { AvField, AvForm } from 'availity-reactstrap-validation';
import one from '../../assets/images/pro3/1.jpg'
import user from '../../assets/images/user.png';
import * as machineActions from "../../redux/actions/machineActions";
import * as categoryActions from "../../redux/actions/categoryActions";
import {connect} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import * as productActions from '../../redux/actions/productActions';
import Select from 'react-select';
import { withRouter, Redirect, Router } from 'react-router-dom';
import * as roleActions  from "../../redux/actions/roleActions";
import BeatLoader from "react-spinners/BeatLoader";


export class EditProduct_machine extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            quantite: 1,
            price: '',
            product_id:'',
            category: '',
            ProcOptions: [],
            isLoading: false,
            visible: false,
            roles: null,
            loading: false,
            
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        // this.productHandleChange = this.productHandleChange.bind(this)
        // this.categoryHandleChange = this.categoryHandleChange.bind(this)
        
    }
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    // productHandleChange = (product_id) => {
    //     this.setState({ product_id });
    // }

    IncrementItem = () => {
        this.setState(prevState => {
            if (prevState.quantite < 9) {
                return {
                    quantite: prevState.quantite + 1
                }
            } else {
                return null;
            }
        });
    }
    DecreaseItem = () => {
        this.setState(prevState => {
            if (prevState.quantite > 0) {
                return {
                    quantite: prevState.quantite - 1
                }
            } else {
                return null;
            }
        });
    }


    //image upload
    _handleSubmit(e) {
        e.preventDefault();
    }


    handleSubmitChange = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true
        })
        // // console.log(this.state.AllOptions)
        this.props.newProduct(this.state);
       
        setTimeout(()=> {
            console.log(this.props.addproduct.success)
            if(this.props.addproduct.success === true){
                this.props.history.push('/products/physical/product-list');
            }else{
                this.props.history.push('/products/physical/add-product');
                this.setState({
                    isLoading: false
                })
            }
        }, 1000)
       

    }

    componentDidMount = () => {
    //     let catoptions = [];
    //     let listCategories= [];
    //     let macoptions = [];
    //     let listMachines = [];
    //     let rol = null;

        this.setState({
            loading: true
        })

    //     this.props.categories();

    //     setTimeout(() => {
    //         // console.log(this.props.category.categories)

    //         this.props.category.categories.map(category => {
    //             let item = {
    //                 id: category.category_id,
    //                 Nom: category.name
    //             }
    //             listCategories.push(item);
    //         })
            
    //         // console.log('maaa',listCategories)
    //         listCategories.map(listCategorie => {
    //             let option = 
    //                 {value:listCategorie.id, label: listCategorie.Nom }
                
    //             catoptions.push(option)
    //         })
    //         // console.log('ooooo', catoptions)

    //         this.setState({
    //             CatOptions: catoptions
    //         })
    //     }, 1000)

    //     this.props.actionsdetailRole(localStorage.getItem('roles'))

    //     setTimeout(() => {
    //         this.props.roledetails.role.map(rl => {
    //             rol = rl.ajouterProduit;
    //         })
    //         this.setState({
    //             roles: rol
    //         })
    //         if(this.props.roledetails.role[0].listeProduit == '1'){
    //             this.setState({
    //                 visible: true
    //             })
    //         }
    //     }, 1000)

    //     this.props.machines();

        setTimeout(() => {
    //         // console.log(this.props.machine.machines)

    //         this.props.machine.machines.map(machine => {
    //             let item = {
    //                 id: machine.id,
    //                 Nom: machine.nom
    //             }
    //             listMachines.push(item);
    //         })
            
    //         // console.log('maaa',listMachines)
    //         listMachines.map(listMachine => {
    //             let option = 
    //                 {value:listMachine.id, label: listMachine.Nom }
                
    //             macoptions.push(option)
    //         })
    //         // console.log('aaaa', macoptions)
            this.setState({
                // MacOptions: macoptions,
                loading: false
            })
        }, 1000)
    }

    render() {
        const {loading, visible, roles, isLoading, CatOptions, images, MacOptions, nom , price, quantite, unite, reference, description, machine_id, category_id} = this.state
        // console.log(dummyimgs)
        const customStyles = {
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                width: '715px'
            }),
        }
        if(loading){
            return(
                <div style={{display: "flex", justifyContent: "center", 
                            alignItems: "center", width: "100%", height: "100vh"}}>
                   <BeatLoader color={"#EC1C5B"} loading={loading} size={50} />
                </div>
            )
        }else{
            // if(roles === '1'){
                return (
                    <Fragment>
                        <Breadcrumb title="Ajout d'un produit" parent="Produits" />

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <Link type="button" to={`/machines/machine-details/${this.props.match.params.id}`} className="btn btn-primary">Retour</Link>
                                        </div>
                                        <div className="card-body">
                                            <div className="row product-adding">
                                                <div className="col-xl-7">
                                                   
                                                    <ToastContainer />
                                                    <AvForm className="needs-validation add-product-form" onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                                                        <div className="form form-label-center">
                                                            <div className="form-group mb-3 row">
                                                                <label className="col-xl-3 col-sm-4 mb-0">Nom du Produit :</label>
                                                                <div className="col-xl-8 col-sm-7">
                                                                    <Select
                                                                    styles={customStyles}
                                                                    name="nom"
                                                                    value={nom}
                                                                    // options={MacOptions}
                                                                    onChange={this.productHandleChange}
                                                                    required="" 
                                                                />
                                                                </div>
                                                                <div className="valid-feedback">Acceptable!</div>
                                                            </div>
                                                            <div className="form-group mb-3 row">
                                                                <label className="col-xl-3 col-sm-4 mb-0">Prix :</label>
                                                                <div className="col-xl-8 col-sm-7">
                                                                    <AvField className="form-control mb-0" 
                                                                        name="price" id="validationCustom02"
                                                                        value={price}
                                                                        onChange={this.handleInputChange} 
                                                                        type="number" 
                                                                        required 
                                                                    />
                                                                </div>
                                                                <div className="valid-feedback">Acceptable!</div>
                                                            </div>
                                                            <div className="form-group row">
                                                                <label className="col-xl-3 col-sm-4 mb-0">Quantité d'articles :</label>
                                                                <fieldset className="qty-box ml-0">
                                                                    <div className="input-group bootstrap-touchspin">
                                                                        <div className="input-group-prepend">
                                                                            <button className="btn btn-primary btn-square bootstrap-touchspin-down" type="button" onClick={this.DecreaseItem} >
                                                                                <i className="fa fa-minus"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div className="input-group-prepend">
                                                                            <span className="input-group-text bootstrap-touchspin-prefix" ></span>
                                                                        </div>
                                                                        <input className="touchspin form-control" 
                                                                            type="number"
                                                                            name='quantite'
                                                                            value={quantite} 
                                                                            onChange={this.handleInputChange} 
                                                                        />
                                                                        <div className="input-group-append">
                                                                            <span className="input-group-text bootstrap-touchspin-postfix"></span>
                                                                        </div>
                                                                        <div className="input-group-append ml-0">
                                                                            <button className="btn btn-primary btn-square bootstrap-touchspin-up" type="button" onClick={this.IncrementItem}>
                                                                                <i className="fa fa-plus"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        </div>

                                                        <div className="offset-xl-3 offset-sm-4">
                                                            <button type="submit" className="btn btn-primary mr-2"
                                                            disabled={isLoading}
                                                            onClick={this.handleSubmitChange}
                                                            >Ajouter</button>
                                                            {visible == true ?
                                                                (
                                                                    <Link to="/products/physical/product-list" 
                                                                        type="button" className="btn btn-secondary"
                                                                    >Annuler</Link>
                                                                ):
                                                                null
                                                            }
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )
            // }else{
            //     return(
            //         <Fragment>

            //         </Fragment>
            //     )
            // }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        // machine: state.machine,
        // category: state.category,
        // addproduct: state.addproduct,
        roledetails: state.roledetails
    }
}
const mapDispatchToProps = (dispatch) =>{
    return {
        // machines: () => {dispatch( machineActions.machines())},
        // categories: () => {dispatch( categoryActions.categories())},
        // newProduct: (product) => {dispatch(productActions.newProduct(product))},
        actionsdetailRole: (rolename) => {dispatch(roleActions.actionsdetailRole(rolename))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditProduct_machine))
