export const machineConstants = {

    ADMIN_MACHINE_REQUEST : 'ADMIN_MACHINE_REQUEST',
    ADMIN_MACHINE_SUCCESS : 'ADMIN_MACHINE_SUCCESS',
    ADMIN_MACHINE_FAIL : 'ADMIN_MACHINE_FAIL',

    ALL_MACHINE_REQUEST : 'ALL_MACHINE_REQUEST',
    ALL_MACHINE_SUCCESS : 'ALL_MACHINE_SUCCESS',
    ALL_MACHINE_FAIL : 'ALL_MACHINE_FAIL',

    NEW_MACHINE_REQUEST : 'NEW_MACHINE_REQUEST',
    NEW_MACHINE_SUCCESS : 'NEW_MACHINE_SUCCESS',
    NEW_MACHINE_RESET : 'NEW_MACHINE_RESET',
    NEW_MACHINE_FAIL : 'NEW_MACHINE_FAIL',

    DELETE_MACHINE_REQUEST : 'DELETE_MACHINE_REQUEST',
    DELETE_MACHINE_SUCCESS : 'DELETE_MACHINE_SUCCESS',
    DELETE_MACHINE_RESET : 'DELETE_MACHINE_RESET',
    DELETE_MACHINE_FAIL : 'DELETE_MACHINE_FAIL',

    UPDATE_MACHINE_REQUEST : 'UPDATE_MACHINE_REQUEST',
    UPDATE_MACHINE_SUCCESS : 'UPDATE_MACHINE_SUCCESS',
    UPDATE_MACHINE_RESET : 'UPDATE_MACHINE_RESET',
    UPDATE_MACHINE_FAIL : 'UPDATE_MACHINE_FAIL',

    MACHINE_DETAILS_REQUEST : 'MACHINE_DETAILS_REQUEST',
    MACHINE_DETAILS_SUCCESS : 'MACHINE_DETAILS_SUCCESS',
    MACHINE_DETAILS_FAIL : 'MACHINE_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS',
}