export const maintenanceConstants = {

    ADMIN_MAINTENANCE_REQUEST : 'ADMIN_MAINTENANCE_REQUEST',
    ADMIN_MAINTENANCE_SUCCESS : 'ADMIN_MAINTENANCE_SUCCESS',
    ADMIN_MAINTENANCE_FAIL : 'ADMIN_MAINTENANCE_FAIL',

    ALL_MAINTENANCE_REQUEST : 'ALL_MAINTENANCE_REQUEST',
    ALL_MAINTENANCE_SUCCESS : 'ALL_MAINTENANCE_SUCCESS',
    ALL_MAINTENANCE_FAIL : 'ALL_MAINTENANCE_FAIL',

    NEW_MAINTENANCE_REQUEST : 'NEW_MAINTENANCE_REQUEST',
    NEW_MAINTENANCE_SUCCESS : 'NEW_MAINTENANCE_SUCCESS',
    NEW_MAINTENANCE_RESET : 'NEW_MAINTENANCE_RESET',
    NEW_MAINTENANCE_FAIL : 'NEW_MAINTENANCE_FAIL',

    DELETE_MAINTENANCE_REQUEST : 'DELETE_MAINTENANCE_REQUEST',
    DELETE_MAINTENANCE_SUCCESS : 'DELETE_MAINTENANCE_SUCCESS',
    DELETE_MAINTENANCE_RESET : 'DELETE_MAINTENANCE_RESET',
    DELETE_MAINTENANCE_FAIL : 'DELETE_MAINTENANCE_FAIL',

    UPDATE_MAINTENANCE_REQUEST : 'UPDATE_MAINTENANCE_REQUEST',
    UPDATE_MAINTENANCE_SUCCESS : 'UPDATE_MAINTENANCE_SUCCESS',
    UPDATE_MAINTENANCE_RESET : 'UPDATE_MAINTENANCE_RESET',
    UPDATE_MAINTENANCE_FAIL : 'UPDATE_MAINTENANCE_FAIL',

    MAINTENANCE_MACHINE_REQUEST : 'MAINTENANCE_MACHINE_REQUEST',
    MAINTENANCE_MACHINE_SUCCESS : 'MAINTENANCE_MACHINE_SUCCESS',
    MAINTENANCE_MACHINE_FAIL : 'MAINTENANCE_MACHINE_FAIL',

    MAINTENANCE_DETAILS_REQUEST : 'MAINTENANCE_DETAILS_REQUEST',
    MAINTENANCE_DETAILS_SUCCESS : 'MAINTENANCE_DETAILS_SUCCESS',
    MAINTENANCE_DETAILS_FAIL : 'MAINTENANCE_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS'
    
}