import React, { Component,Fragment } from 'react';
import { Link, withRouter, Redirect, Router } from 'react-router-dom';
import {connect} from "react-redux";
import CKEditors from "react-ckeditor-component";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import BeatLoader from "react-spinners/BeatLoader";
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Breadcrumb from '../common/breadcrumb';
import * as machineActions from "../../redux/actions/machineActions";
import * as allocslideActions from "../../redux/actions/allocslideActions";
import * as slideActions from '../../redux/actions/slideActions';
import * as roleActions  from "../../redux/actions/roleActions";
import Data_slides_Alloc from '../common/dataTables/data_slides_alloc';
import one from '../../assets/images/pro3/1.jpg'
import user from '../../assets/images/user.png';


export class AddSlide_machine extends Component {
    
    constructor(props) {
        super(props)

        this.state = {
            // quantite: 1,
            // price: '',
            slide_id: '',
            machine_id: this.props.match.params.id,
            ProcOptions: [],
            slides: [],
            isLoading: false,
            visible: false,
            roles: null,
            loading: false,
            
        }

        this.handleInputChange = this.handleInputChange.bind(this)
        this.slideHandleChange = this.slideHandleChange.bind(this)        
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    slideHandleChange = (slide_id) => {
        this.setState({ slide_id });
    }

    IncrementItem = () => {
        this.setState(prevState => {
            if (prevState.quantite < 9) {
                return {
                    quantite: prevState.quantite + 1
                }
            } else {
                return null;
            }
        });
    }
    DecreaseItem = () => {
        this.setState(prevState => {
            if (prevState.quantite > 0) {
                return {
                    quantite: prevState.quantite - 1
                }
            } else {
                return null;
            }
        });
    }

    handleSubmitChange = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
            
        })
        // // console.log(this.state.AllOptions)
        this.props.newAllocSlide(this.state);
       
        setTimeout(()=> {
            console.log(this.props.addslideinmachine.success)
            if(this.props.addslideinmachine.success === true){
                this.props.history.push('/machines/machine-details/'+ this.state.machine_id);
            }else{
                this.props.history.push('/machines/add-slide/machine/'+ this.state.machine_id);
                this.setState({
                    isLoading: false
                })
            }
        }, 1000)
       

    }

    componentDidMount = () => {
        let procoptions = [];
        let listslides = [];
    //     let rol = null;

        this.setState({
            loading: true
        })

    //     this.props.actionsdetailRole(localStorage.getItem('roles'))

    //     setTimeout(() => {
    //         this.props.roledetails.role.map(rl => {
    //             rol = rl.ajouterProduit;
    //         })
    //         this.setState({
    //             roles: rol
    //         })
    //         if(this.props.roledetails.role[0].listeProduit == '1'){
    //             this.setState({
    //                 visible: true
    //             })
    //         }
    //     }, 1000)

        this.props.slides();

        setTimeout(() => {
            // console.log('slides',this.props.slide)

            this.props.slide.slides.map(slide => {
                let item = {
                    id: slide.id,
                    image: <img src={slide.filePath} style={{ width: 50, height: 50 }} />,
                    type: slide.type,
                    format: slide.format,
                }
                listslides.push(item);

                this.setState({
                    slides: listslides
                })
            })
            
            // console.log('prooo',listslides)
            listslides.map(listslide => {
                let option = 
                    {value:listslide.Id, label: listslide.FilePath }
                
                procoptions.push(option)
            })
            console.log('ppppp', procoptions)
            this.setState({
                ProcOptions: procoptions,
                loading: false
            })
        }, 1000)
    }

    render() {
        const {loading, visible, roles, isLoading, ProcOptions, slides, nom , price, quantite} = this.state
        // console.log(dummyimgs)
        const customStyles = {
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                width: '715px'
            }),
        }
        if(loading){
            return(
                <div style={{display: "flex", justifyContent: "center", 
                            alignItems: "center", width: "100%", height: "100vh"}}>
                   <BeatLoader color={"#EC1C5B"} loading={loading} size={50} />
                </div>
            )
        }else{
            // if(roles === '1'){
                return (
                    <Fragment>
                        <Breadcrumb title="Affectation d'un slide" parent="Slides" />

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <Link type="button" to={`/machines/machine-details/${this.state.machine_id}`} className="btn btn-primary">Retour</Link>
                                        </div>
                                        <div className="card-body">
                                            <div className="row product-adding">
                                                <div className="col-xl-7">
                                                   
                                                    <ToastContainer />
                                                    <AvForm className="needs-validation add-product-form" onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                                                        <div className="form form-label-center">
                                                            <div className="form-group mb-3 row">
                                                                <label className="col-xl-3 col-sm-4 mb-0">Slide :</label>
                                                                <div className="col-xl-8 col-sm-7">
                                                                    <Select
                                                                    styles={customStyles}
                                                                    name="nom"
                                                                    value={nom}
                                                                    options={ProcOptions}
                                                                    onChange={this.slideHandleChange}
                                                                    required="" 
                                                                />
                                                                </div>
                                                                <div className="valid-feedback">Acceptable!</div>
                                                            </div>
                                                        </div>

                                                        <div className="offset-xl-3 offset-sm-4">
                                                            <button type="submit" className="btn btn-primary mr-2"
                                                            disabled={isLoading}
                                                            onClick={this.handleSubmitChange}
                                                            >Ajouter</button>
                                                            {visible === true ?
                                                                (
                                                                    <Link to="/slides/list-slide" 
                                                                        type="button" className="btn btn-secondary"
                                                                    >Annuler</Link>
                                                                ):
                                                                null
                                                            }
                                                        </div>
                                                    </AvForm>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="batchDelete" className="category-table user-list order-table coupon-list-delete">
                            <Data_slides_Alloc
                                multiSelectOption={true}
                                myData={slides}
                                pageSize={10}
                                pagination={true}
                                machine_id={this.state.machine_id}
                                class="-striped -highlight"
                            />
                        </div>

                    </Fragment>
                )
            // }else{
            //     return(
            //         <Fragment>

            //         </Fragment>
            //     )
            // }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        slide: state.slide,
        // category: state.category,
        addslideinmachine: state.addslideinmachine,
        roledetails: state.roledetails
    }
}
const mapDispatchToProps = (dispatch) =>{
    return {
        slides: () => {dispatch( slideActions.slides())},
        // categories: () => {dispatch( categoryActions.categories())},
        newAllocSlide: (slide) => {dispatch(allocslideActions.newAllocSlide(slide))},
        actionsdetailRole: (rolename) => {dispatch(roleActions.actionsdetailRole(rolename))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddSlide_machine))
