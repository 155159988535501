import { settingsConstants } from "../../constants/settingsConstants";
import axios from "axios";
import { toast } from 'react-toastify';

export const changePassword = (password) => {
    
    return (dispatch) => {
        dispatch({ 
            type: settingsConstants.CHANGE_PASSWORD_REQUEST
        });
        
        const token = localStorage.getItem('token');
        const username = localStorage.getItem('username');

        console.log('password',password);
        console.log('username',username);
        console.log('token',token);
        
        let body = {
            username: username,
            oldpassword: password.oldPassword,
            password: password.newPasswordRepeat,
        }
        // // console.log(" password ", body);
        axios.post('/utilisateur/changepassword', body, {
            headers: {
                'USER-KEY': `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        }).then((response) =>{
            console.log('response', response)
            if(response.data.success === true){
                const {success} = response.data
                dispatch({ 
                    type: settingsConstants.CHANGE_PASSWORD_SUCCESS,
                    payload: {success: success}
                });
                toast.success(response.data.full_messages[0])
            }else {
                dispatch({ 
                    type: settingsConstants.CHANGE_PASSWORD_FAIL,
                    payload: {error: response.data.full_messages[0]}
                });
                toast.error(response.data.full_messages[0])
                
            }
        }).catch((err) =>{
            console.log(err)
        })
    
    };
}