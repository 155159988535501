export const ordersConstants = {
    CREATE_ORDER_REQUEST : 'CREATE_ORDER_REQUEST',
    CREATE_ORDER_SUCCESS : 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_FAIL : 'CREATE_ORDER_FAIL',

    MY_ORDERS_REQUEST : 'MY_ORDERS_REQUEST',
    MY_ORDERS_SUCCESS : 'MY_ORDERS_SUCCESS',
    MY_ORDERS_FAIL : 'MY_ORDERS_FAIL',

    ALL_ORDERS_REQUEST : 'ALL_ORDERS_REQUEST',
    ALL_ORDERS_SUCCESS : 'ALL_ORDERS_SUCCESS',
    ALL_ORDERS_FAIL : 'ALL_ORDERS_FAIL',

    UPDATE_ORDER_REQUEST : 'UPDATE_ORDER_REQUEST',
    UPDATE_ORDER_SUCCESS : 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_RESET : 'UPDATE_ORDER_RESET',
    UPDATE_ORDER_FAIL : 'UPDATE_ORDER_FAIL',

    ORDER_DETAILS_REQUEST : 'ORDER_DETAILS_REQUEST',
    ORDER_DETAILS_SUCCESS : 'ORDER_DETAILS_SUCCESS',
    ORDER_DETAILS_FAIL : 'ORDER_DETAILS_FAIL',

    ORDER_MACHINE_REQUEST : 'ORDER_MACHINE_REQUEST',
    ORDER_MACHINE_SUCCESS : 'ORDER_MACHINE_SUCCESS',
    ORDER_MACHINE_FAIL : 'ORDER_MACHINE_FAIL',

    DELETE_ORDER_REQUEST : 'DELETE_ORDER_REQUEST',
    DELETE_ORDER_SUCCESS : 'DELETE_ORDER_SUCCESS',
    DELETE_ORDER_RESET : 'DELETE_ORDER_RESET',
    DELETE_ORDER_FAIL : 'DELETE_ORDER_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS'

}