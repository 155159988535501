import React, { Component, useEffect } from "react";
import ReactDOM from "react-dom";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";
import "./index.scss";
import App from "./components/app";
import { ScrollContext } from "react-router-scroll-4";
import { authConstants } from "../src/constants/userConstants";

import axios from "axios";

import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// Import of reducers
import {
    allocproductDetailsReducer,
    allocproductReducer,
    newAllocProductReducer,
    allocproductsReducer,
    allocProductByMachineReducer,
} from "./redux/reducers/allocproductsReducer";
import {
    allocslideDetailsReducer,
    allocslideReducer,
    newAllocSlideReducer,
    allocslidesReducer,
    allocSlideByMachineReducer,
} from "./redux/reducers/allocslidesReducer";
import {
    appromonnaiesReducer,
    appromonnaieByMachineReducer,
    newAppromonnaieReducer,
    appromonnaieReducer,
    appromonnaieDetailsReducer,
} from "./redux/reducers/appromonnaieReducers";
import {
    approproduitByMachineReducer,
    approproduitsReducer,
    newApproproduitReducer,
    approproduitReducer,
    approproduitDetailsReducer,
} from "./redux/reducers/approproduitReducers";
import {
    newRoleReducer,
    roleReducer,
    rolesReducer,
    roleDetailsReducer,
    rolePrivateReducer,
} from "./redux/reducers/rolesReducer";
import {
    logsByMachineReducer,
    logssReducer,
    newLogsReducer,
    logsReducer,
    logsDetailsReducer,
} from "./redux/reducers/logsReducers";
import {
    categoryReducer,
    categoriesReducer,
    newCategoryReducer,
    categoryDetailsReducer,
} from "./redux/reducers/categoryReducers";
import {
    machinesReducer,
    newMachineReducer,
    machineReducer,
    machineDetailsReducer,
} from "./redux/reducers/machineReducers";
import {
    maintenanceByMachineReducer,
    maintenancesReducer,
    newMaintenanceReducer,
    maintenanceReducer,
    maintenanceDetailsReducer,
} from "./redux/reducers/maintenanceReducers";
import {
    ordersByMachineReducer,
    newOrderReducer,
    myOrdersReducer,
    orderDetailsReducer,
    allOrdersReducer,
    orderReducer,
} from "./redux/reducers/orderReducers";
import {
    paiementsByMachineReducer,
    paiementsReducer,
    newPaiementReducer,
    paiementReducer,
    paiementDetailsReducer,
} from "./redux/reducers/paiementReducers";
import {
    piecesReducer,
    newPieceReducer,
    pieceReducer,
    pieceDetailsReducer,
} from "./redux/reducers/pieceReducers";
import {
    productsReducer,
    newProductReducer,
    productReducer,
    productDetailsReducer,
} from "./redux/reducers/productReducers";
import {
    remboursementsByMachineReducer,
    remboursementsReducer,
    newRemboursementReducer,
    remboursementReducer,
    remboursementDetailsReducer,
} from "./redux/reducers/remboursementReducers";
import {
    slidesReducer,
    newSlideReducer,
    slideReducer,
    slideDetailsReducer,
} from "./redux/reducers/slideReducers";
import {
    transactionomsByMachineReducer,
    transactionomsReducer,
    transactionomReducer,
    newTransactionomReducer,
    transactionomDetailsReducer,
} from "./redux/reducers/transactionomReducers";
import { changePasswordReducer } from "./redux/reducers/settingsReducers";
import {
    authReducer,
    userReducer,
    forgotPasswordReducer,
    newUsersReducer,
    allUsersReducer,
    userDetailsReducer,
} from "./redux/reducers/userReducers";

// Components
import Dashboard from "./components/dashboard";

// Products physical
import Category from "./components/products/physical/category";
import Sub_category from "./components/products/physical/sub-category";
import Product_list from "./components/products/physical/product-list";
import Add_product from "./components/products/physical/add-product";
import Edit_products from "./components/products/physical/edit-products";
import Product_detail from "./components/products/physical/product-detail";
import Edit_category from "./components/products/physical/edit-category";
//Product Digital
import Digital_category from "./components/products/digital/digital-category";
import Digital_sub_category from "./components/products/digital/digital-sub-category";
import Digital_pro_list from "./components/products/digital/digital-pro-list";
import Digital_add_pro from "./components/products/digital/digital-add-pro";

//Sales
import Orders from "./components/sales/orders";
import Transactions_sales from "./components/sales/transactions-sales";
import Mobile_pay from "./components/sales/mobile-pay";
import Repayment_sales from "./components/sales/repayment";

//Coupons
import ListCoupons from "./components/coupons/list-coupons";
import Create_coupons from "./components/coupons/create-coupons";

//Pages
import ListPages from "./components/pages/list-page";
import Create_page from "./components/pages/create-page";
import Media from "./components/media/media";
import List_menu from "./components/menus/list-menu";
import Create_menu from "./components/menus/create-menu";

import List_vendors from "./components/vendors/list-vendors";
import Create_vendors from "./components/vendors/create.vendors";
import Translations from "./components/localization/translations";
import Rates from "./components/localization/rates";
import Taxes from "./components/localization/taxes";
import Profile from "./components/settings/profile";
import Reports from "./components/reports/report";
import Invoice from "./components/invoice";
import Datatable from "./components/common/datatable";
import Login from "./components/auth/login";

import Create_currency from "./components/supply/currency/create_currency";
import List_currency from "./components/supply/currency/list-currency";
import Edit_currency from "./components/supply/currency/edit-currency";

import Add_piece from "./components/pieces/add-piece";
import List_piece from "./components/pieces/list-piece";
import Edit_piece from "./components/pieces/edit-piece";

import Create_product from "./components/supply/products/create-product";
import List_product from "./components/supply/products/list-product";
import Edit_product from "./components/supply/products/edit-product";

import List_user from "./components/users/list-user";
import Edit_user from "./components/users/edit-user";
import Create_user from "./components/users/create-user";

import Create_machine from "./components/machines/create-machine";
import List_machine from "./components/machines/list-machine";
import Edit_machine from "./components/machines/Edit-machine";
import Detail_machine from "./components/machines/machine-details";

import Create_maintain from "./components/maintains/create-maintain";
import List_maintain from "./components/maintains/list-maintain";
import Edit_maintain from "./components/maintains/edit-maintain";

import Create_slide from "./components/slides/create-slide";
import CreateSlide from "./components/slides/CreateSlide.component";
import List_slide from "./components/slides/list-slide";
import Edit_slide from "./components/slides/edit-slide";

// import Create_log from './components/logs/create-log';
import Log from "./components/logs/log";

import { Change_profile } from "./components/settings/change-profile";
import Change_password from "./components/settings/change-password";

import Add_roles from "./components/roles/add-roles";
import Roles_list from "./components/roles/roles-list";
import Change_roles from "./components/roles/change-role";
import Edit_roles from "./components/roles/edit-roles";

import PageNotFound from "./components/PageNotFound";

import ProtectedRoute from "./routes/ProtectedRoute";
import AddProduct_machine from "./components/machines/addProduct-machine";
import EditProduct_machine from "./components/machines/editProduct-machine";

import AddSlide_machine from "./components/machines/addSlide-machine";

// import PrivateRouteListAppromonnaie from './routes/appromonnaies/PrivateRouteListAppromonnaie';

axios.defaults.baseURL = process.env.REACT_APP_NEXT_PUBLIC_REST_API;


const rootReducers = combineReducers({
    auth: authReducer,

    appromonnaie: appromonnaiesReducer,
    approproduit: approproduitsReducer,
    log: logssReducer,
    category: categoriesReducer,
    machine: machinesReducer,
    maintenance: maintenancesReducer,
    order: allOrdersReducer,
    paiement: paiementsReducer,
    slide: slidesReducer,
    product: productsReducer,
    user: allUsersReducer,
    role: rolesReducer,
    piece: piecesReducer,

    editproduct: productReducer,
    deactivateproduct: productReducer,
    editappromonnaie: appromonnaieReducer,
    editapproproduit: approproduitReducer,
    editmachine: machineReducer,
    editmaintenance: maintenanceReducer,
    editslide: slideReducer,
    editcategory: categoryReducer,
    edituser: userReducer,
    editrole: roleReducer,
    editpiece: pieceReducer,

    maindetails: maintenanceDetailsReducer,
    machdetails: machineDetailsReducer,
    appromondetails: appromonnaieDetailsReducer,
    approprodetails: approproduitDetailsReducer,
    prodetails: productDetailsReducer,
    slidetails: slideDetailsReducer,
    catdetails: categoryDetailsReducer,
    userdetails: userDetailsReducer,
    roledetails: roleDetailsReducer,
    roleprivate: rolePrivateReducer,
    piecedetail: pieceDetailsReducer,

    addappromonnaie: newAppromonnaieReducer,
    addapproproduit: newApproproduitReducer,
    addmaintenance: newMaintenanceReducer,
    addmachine: newMachineReducer,
    addslide: newSlideReducer,
    addcategory: newCategoryReducer,
    adduser: newUsersReducer,
    addrole: newRoleReducer,
    addproduct: newProductReducer,
    addpiece: newPieceReducer,
    addproductinmachine: newAllocProductReducer,
    addslideinmachine: newAllocSlideReducer,

    remboursement: remboursementsReducer,
    transactionom: transactionomsReducer,
    //   userReducer, forgotPasswordReducer,
    maintenancebymachine: maintenanceByMachineReducer,
    appromonnaiebymachine: appromonnaieByMachineReducer,
    approproduitbymachine: approproduitByMachineReducer,
    logbymachine: logsByMachineReducer,
    allocproductbymachine: allocProductByMachineReducer,
    allocslidebymachine: allocSlideByMachineReducer,
    ordersbymachine: ordersByMachineReducer,
    paiementsbymachine: paiementsByMachineReducer,
    remboursementsbymachine: remboursementsByMachineReducer,
    transactionomsbymachine: transactionomsByMachineReducer,
    changepassword: changePasswordReducer,
});

let initialState = {
    roledetails: {
        loading: false,
        role: [
            {
                id: 1,
                name: "ROLE_USER",
                description: "For standard utilisateurs",
                dashboard: "1",
                listeProduit: "1",
                ajouterProduit: "1",
                modifierProduit: "1",
                supprimerProduit: "1",
                listeCategorie: "1",
                ajouterCategorie: "1",
                modifierCategorie: "1",
                supprimerCategorie: "1",
                commandes: "1",
                paiements: "1",
                remboursements: "1",
                mobileMoney: "1",
                listeApproMonnaie: "1",
                ajouterApproMonnaie: "1",
                modifierApproMonnaie: "1",
                supprimerApproMonnaie: "1",
                listeApproProduit: "1",
                ajouterApproProduit: "1",
                modifierApproProduit: "1",
                supprimerApproProduit: "1",
                listeMachine: "1",
                ajouterMachine: "1",
                modifierMachine: "1",
                supprimerMachine: "1",
                listeMaintenance: "1",
                ajouterMaintenance: "1",
                modifierMaintenance: "1",
                supprimerMaintenance: "1",
                listePiece: "1",
                ajouterPiece: "1",
                modifierPiece: "1",
                supprimerPiece: "1",
                listeSlide: "1",
                ajouterSlide: "1",
                modifierSlide: "1",
                supprimerSlide: "1",
                log: "1",
                statistique: "1",
                listeUtilisateur: "1",
                ajouterUtilisateur: "1",
                modifierUtilisateur: "1",
                supprimerUtilisateur: "1",
                listeRole: "1",
                ajouterRole: "1",
                modifierRole: "1",
                supprimerRole: "1",
                createdAt: "2021-05-13T19:38:37.000Z",
                updatedAt: "2021-07-13T16:05:16.000Z",
            },
        ],
        success: true,
        error: null,
    },
};

const store = createStore(rootReducers, initialState, applyMiddleware(thunk));
window.store = store;

class Root extends Component {
    componentDidMount = () => {
        //    localStorage.removeItem('token')
        const token = localStorage.getItem("token");
        console.log(token);
        // localStorage.
        // let token = localStorage.removeItem('token')
        // console.log(token)
        // if(!token){
        //     <Redirect to="/login"/>
        // }
    };

    // useEffect(() =>{
    //     if (!token) {
    //         dispatch(login())
    //     }
    // })
    render() {
        return (
            <Provider store={store}>
                <Router basename={"/"}>
                    <ScrollContext>
                        <Switch>
                            <Route exact path="/login" component={Login} />
                            {/* <Redirect to="/404" /> */}
                            {/* <Route exact path='/login' component={Login} /> */}
                            <App>
                                <ProtectedRoute exact path="/" component={Dashboard} />

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/products/physical/category`}
                                    component={Category}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/products/physical/edit-category/:id`}
                                    component={Edit_category}
                                />
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/products/physical/sub-category`} component={Sub_category} /> */}
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/products/physical/product-list`}
                                    component={Product_list}
                                />
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/products/physical/product-detail`} component={Product_detail} /> */}
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/products/physical/add-product`}
                                    component={Add_product}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/products/physical/edit-products/:id`}
                                    component={Edit_products}
                                />

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/products/digital/digital-category`} component={Digital_category} /> */}
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/products/digital/digital-sub-category`} component={Digital_sub_category} /> */}
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/products/digital/digital-product-list`} component={Digital_pro_list} /> */}
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/products/digital/digital-add-product`} component={Digital_add_pro} /> */}

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/sales/orders`}
                                    component={Orders}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/sales/transactions`}
                                    component={Transactions_sales}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/sales/repayment`}
                                    component={Repayment_sales}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/sales/mobile-pay`}
                                    component={Mobile_pay}
                                />

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/coupons/list-coupons`} component={ListCoupons} /> */}
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/coupons/create-coupons`} component={Create_coupons} /> */}

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/pages/list-page`} component={ListPages} /> */}
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/pages/create-page`} component={Create_page} /> */}

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/media`} component={Media} /> */}

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/menus/list-menu`} component={List_menu} />
                                <ProtectedRoute path={`${process.env.PUBLIC_URL}/menus/create-menu`} component={Create_menu} /> */}

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/users/list-user`}
                                    component={List_user}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/users/create-user`}
                                    component={Create_user}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/users/edit-user/:id`}
                                    component={Edit_user}
                                />

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/vendors/list_vendors`} component={List_vendors} />
                                <ProtectedRoute path={`${process.env.PUBLIC_URL}/vendors/create-vendors`} component={Create_vendors} /> */}

                                {/* <Route path={`${process.env.PUBLIC_URL}/localization/transactions`} component={Translations} />
                                <Route path={`${process.env.PUBLIC_URL}/localization/currency-rates`} component={Rates} />
                                <Route path={`${process.env.PUBLIC_URL}/localization/taxes`} component={Taxes} /> */}

                                {/* <Route path={`${process.env.PUBLIC_URL}/reports/report`} component={Reports} /> */}

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/settings/profile`} component={Profile} /> */}
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/settings/change-profile`} component={Change_profile} /> */}
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/settings/change-password`}
                                    component={Change_password}
                                />

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/invoice`} component={Invoice} /> */}

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/data-table`} component={Datatable} /> */}

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/supply/currency/create-currency/machine/:id`}
                                    component={Create_currency}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/supply/currency/list-currency`}
                                    component={List_currency}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/supply/currency/edit-currency/:idCur/machine/:idMac`}
                                    component={Edit_currency}
                                />

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/supply/products/create-product/machine/:id`}
                                    component={Create_product}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/supply/products/list-product`}
                                    component={List_product}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/supply/products/edit-product/:idPro/machine/:idMac`}
                                    component={Edit_product}
                                />

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/pieces/add-piece`}
                                    component={Add_piece}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/pieces/list-piece`}
                                    component={List_piece}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/pieces/edit-piece/:id`}
                                    component={Edit_piece}
                                />

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/machines/create-machine`}
                                    component={Create_machine}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/machines/list-machine`}
                                    component={List_machine}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/machines/edit-machine/:id`}
                                    component={Edit_machine}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/machines/machine-details/:id`}
                                    component={Detail_machine}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/machines/add-product/machine/:id`}
                                    component={AddProduct_machine}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/machines/edit-product/:idPro/machine/:id`}
                                    component={EditProduct_machine}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/machines/add-slide/machine/:id`}
                                    component={AddSlide_machine}
                                />

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/maintains/create-maintain/machine/:id`}
                                    component={Create_maintain}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/maintains/list-maintain`}
                                    component={List_maintain}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/maintains/edit-maintain/:idMain/machine/:idMac`}
                                    component={Edit_maintain}
                                />

                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/slides/create-slide`} component={Create_slide}/> */}
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/slides/create-slide`}
                                    component={CreateSlide}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/slides/list-slide`}
                                    component={List_slide}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/slides/edit-slide/:id`}
                                    component={Edit_slide}
                                />

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/logs/Log`}
                                    component={Log}
                                />
                                {/* <ProtectedRoute path={`${process.env.PUBLIC_URL}/logs/add-logs`} component={Add_logs}/>
                                <ProtectedRoute path={`${process.env.PUBLIC_URL}/logs/edit-log/:id`} component={Edit_log}/> */}

                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/roles/add-roles`}
                                    component={Add_roles}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/roles/roles-list`}
                                    component={Roles_list}
                                />
                                <ProtectedRoute
                                    path={`${process.env.PUBLIC_URL}/roles/edit-roles/:id`}
                                    component={Edit_roles}
                                />
                                {/* <Route component={PageNotFound} /> */}
                            </App>
                        </Switch>
                    </ScrollContext>
                </Router>
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById("root"));
