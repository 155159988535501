import {allocslidesConstants} from '../../constants/allocslideConstants'

export const allocslidesReducer = (state = { slides: [] }, action) => {
    switch (action.type) {
        case allocslidesConstants.ALL_SLIDES_MACHINE_REQUEST:
        // case allocslidesConstants.ADMIN_MACHINE_REQUEST:
            return {
                loading: true,
                slides: []
            }

        case allocslidesConstants.ALL_SLIDES_MACHINE_SUCCESS:
            return {
                loading: false,
                slides: action.payload.slides,
                slidesCount: action.payload.slidesCount,
                // resPerPage: action.payload.resPerPage,
                // filteredmachinesCount: action.payload.filteredmachinesCount
            }

        // case allocslidesConstants.ADMIN_MACHINE_SUCCESS:
        //     return {
        //         loading: false,
        //         machines: action.payload
        //     }

        case allocslidesConstants.ALL_SLIDES_MACHINE_FAIL:
        // case allocslidesConstants.ADMIN_MACHINE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case allocslidesConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const allocSlideByMachineReducer = (state = { alloc_slides: [] }, action) => {
    switch (action.type) {
        case allocslidesConstants.SLIDES_MACHINE_REQUEST:
        // case maintenanceConstants.ADMIN_MAINTENANCE_REQUEST:
            return {
                loading: true,
                alloc_slides: []
            }

        case allocslidesConstants.SLIDES_MACHINE_SUCCESS:
            return {
                loading: false,
                alloc_slides: action.payload.alloc_slides,
                // slidesCount: action.payload.slidesCount,
                // resPerPage: action.payload.resPerPage,
                // filteredslidesCount: action.payload.filteredslidesCount
            }

        // case maintenanceConstants.ADMIN_MAINTENANCE_SUCCESS:
        //     return {
        //         loading: false,
        //         slides: action.payload
        //     }

        case allocslidesConstants.SLIDES_MACHINE_FAIL:
        // case maintenanceConstants.ADMIN_MAINTENANCE_FAIL:
            return {
                loading: false,
                error: action.payload.error
            }

        case allocslidesConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const newAllocSlideReducer = (state = { slide: {} }, action) => {
    switch (action.type) {

        case allocslidesConstants.NEW_SLIDES_MACHINE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case allocslidesConstants.NEW_SLIDES_MACHINE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                // machine: action.payload.machine,
                // token: action.payload.token
            }

        case allocslidesConstants.NEW_SLIDES_MACHINE_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        case allocslidesConstants.NEW_SLIDES_MACHINE_RESET:
            return {
                ...state,
                success: false
            }

        case allocslidesConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const allocslideReducer = (state = {}, action) => {
    switch (action.type) {

        case allocslidesConstants.DELETE_SLIDES_MACHINE_REQUEST:
        // case allocslidesConstants.UPDATE_SLIDES_MACHINE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case allocslidesConstants.DELETE_SLIDES_MACHINE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.isDeleted
            }

        // case allocslidesConstants.UPDATE_SLIDES_MACHINE_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         isUpdated: action.payload.isUpdated
        //     }


        case allocslidesConstants.DELETE_SLIDES_MACHINE_FAIL:
        // case allocslidesConstants.UPDATE_SLIDES_MACHINE_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        // case allocslidesConstants.DELETE_SLIDES_MACHINE_RESET:
        //     return {
        //         ...state,
        //         isDeleted: false
        //     }

        // case allocslidesConstants.UPDATE_SLIDES_MACHINE_RESET:
        //     return {
        //         ...state,
        //         isUpdated: false
        //     }

        case allocslidesConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const allocslideDetailsReducer = (state = { slide: {} }, action) => {
    switch (action.type) {

        case allocslidesConstants.SLIDES_MACHINE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case allocslidesConstants.SLIDES_MACHINE_DETAILS_SUCCESS:
            return {
                loading: false,
                slide: action.payload.slide
            }

        case allocslidesConstants.SLIDES_MACHINE_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        case allocslidesConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}
