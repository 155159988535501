import React, { Component } from 'react'
import man from '../../../assets/images/dashboard/user_man.png'

export class User_panel extends Component {
    constructor(props){
        super(props);

        this.state = {
            currentUser: '',
            roleUser: ''
        };
    }
    componentDidMount = () =>{ 
        this.setState({
            roleUser: localStorage.getItem('roles'),
            currentUser: localStorage.getItem('username')
        })
    }
    render() {
        const {currentUser, roleUser} = this.state
        return (
            <div>
                <div className="sidebar-user text-center">
                    <div><img className="img-60 rounded-circle lazyloaded blur-up" src={man} alt="#" />
                    </div>
                    <h6 className="mt-3 f-14">{currentUser}</h6>
                    <p>{roleUser}</p>
                </div>
            </div>
        )
    }
}

export default User_panel

