import { machineConstants } from "../../constants/machineConstants";
import axios from "axios";
import { toast } from 'react-toastify';

export const machines = () => {

    return (dispatch) => {
        dispatch({
            type: machineConstants.ALL_MACHINE_REQUEST
        });

        axios.get('/machines')
            .then((response) => {
                console.log(response);
                if (response.data.success === true) {
                    const { machines } = response.data
                    const machinesCount = response.data.page_meta.total_items_count
                    // console.log(machines)
                    dispatch({
                        type: machineConstants.ALL_MACHINE_SUCCESS,
                        payload: { machines: machines, machinesCount: machinesCount }
                    });
                } else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);

                        dispatch({
                            type: machineConstants.ALL_MACHINE_FAIL,
                            payload: { error: response.data.full_messages[0] }
                        });
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });
    }
}

export const newMachine = (machine) => {
    console.log('mace', machine)

    return (dispatch) => {
        dispatch({
            type: machineConstants.NEW_MACHINE_REQUEST
        });

        let formData = new FormData();
        formData.append('images', machine.images);
        formData.append('status', machine.status);
        formData.append('etat', "1");
        formData.append('nom', machine.nom);
        formData.append('localisation', machine.localisation);
        formData.append('type', machine.type.value);
        formData.append('lien', machine.lien);
        formData.append('montant', parseInt(machine.montant,10));
        formData.append('description', machine.description);
        formData.append('utilisateurid', localStorage.getItem('userid'));

        console.log(formData)
       
        const token = localStorage.getItem('token');
        let config = {
            headers: {
                'Content-type': 'multipart/form-data',
                'USER-KEY': `Bearer ${token}`
            }
        }
        axios.post('/machine', formData, config)
        .then((response) => {
            console.log(response.data)
            if(response.data.success === true){
                const { success } = response.data
                
                dispatch({
                    type: machineConstants.NEW_MACHINE_SUCCESS,
                    payload: {success: success }
                })
                toast.success(response.data.full_messages[0])
            }
            else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    // const {success} = response.data
                    // toast.error(response.data.full_messages[0]);
                    dispatch({
                        type: machineConstants.NEW_MACHINE_FAIL,
                        // payload: { success: success }
                    });
                    for(var i=0; i<response.data.full_messages[i]; i++){
                        toast.error(response.data.full_messages[i])
                    }
                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });

    }
}

export const editMachine = (machine) => {
    // console.log()

    return (dispatch) => {
        dispatch({
            type: machineConstants.UPDATE_MACHINE_REQUEST
        });
        console.log('upmac',machine)
        let body = {
            etat: "1",
            status: "1",
            nom: machine.nom,
            type: machine.type['value'],
            localisation: machine.localisation,
            lien: machine.lien,
            montant: parseInt(machine.montant,10),
            description: machine.description,
            utilisateurid: localStorage.getItem('userid')
        }
        console.log(body)
        const token = localStorage.getItem('token');
        let config = {
            headers: {
            'Content-Type': 'application/json',
            'USER-KEY': `Bearer ${token}`
            }
        }
        console.log(token)
        axios.put(`/machine/${machine.id}`, body, config)
        .then((response) => {
            console.log(response)
            if(response.data.success === true){
                const { success } = response.data
                
                dispatch({
                    type: machineConstants.UPDATE_MACHINE_SUCCESS,
                    payload: {isUpdated: success }
                })
                toast.success(response.data.full_messages[0])
            }else{
                if (response.data.success === false) {
                    // console.log(response.data.full_messages[0])
                    // const {success} = response.data
                    // toast.error(response.data.full_messages[0]);
                    dispatch({
                        type: machineConstants.UPDATE_MACHINE_FAIL,
                        // payload: { success: success }
                    });
                    for(var i=0; i<response.data.full_messages[i]; i++){
                        toast.error(response.data.full_messages[i])
                    }
                }
            }
        })
        .catch((error) => {
            console.log("Oops, Request failed!");
        });

    }
}

export const machineDetail = (paramID) => {

    console.log(paramID)
    return (dispatch) => {
        dispatch({
            type: machineConstants.MACHINE_DETAILS_REQUEST
        });
        
        // console.log(paramID)
        axios.get(`/machine/${paramID}`)
            .then((response) => {
                // console.log(response);
                if (response.data.success === true) {
                    const machine  = response.data

                    console.log('machine details',machine)

                    dispatch({
                        type: machineConstants.MACHINE_DETAILS_SUCCESS,
                        payload: { machine: machine }
                    });
                }else {
                    if (response.data.success === false) {
                        // console.log(response.data.full_messages[0])
                        toast.error(response.data.full_messages[0]);

                        dispatch({
                            type: machineConstants.MACHINE_DETAILS_FAIL,
                            // payload: { error: success }
                        });
                        for(var i=0; i<response.data.full_messages[i]; i++){
                            toast.error(response.data.full_messages[i])
                        }
                    }
                }
            })
            .catch((error) => {
                console.log("Oops, Request failed!");
            });
    }
}