import { settingsConstants } from "../../constants/settingsConstants";

const initialState ={
    password: {}
}

export const changePasswordReducer = (state = initialState, action) => {

    switch (action.type) {

        case settingsConstants.CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                loading: true,
            } 

        case settingsConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                success: action.payload.success,
            }

        case settingsConstants.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            }

        case settingsConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}