export const remboursementConstants = {
    ADMIN_REMBOURSEMENT_REQUEST : 'ADMIN_REMBOURSEMENT_REQUEST',
    ADMIN_REMBOURSEMENT_SUCCESS : 'ADMIN_REMBOURSEMENT_SUCCESS',
    ADMIN_REMBOURSEMENT_FAIL : 'ADMIN_REMBOURSEMENT_FAIL',
   
    ALL_REMBOURSEMENT_REQUEST : 'ALL_REMBOURSEMENT_REQUEST',
    ALL_REMBOURSEMENT_SUCCESS : 'ALL_REMBOURSEMENT_SUCCESS',
    ALL_REMBOURSEMENT_FAIL : 'ALL_REMBOURSEMENT_FAIL',
   
    NEW_REMBOURSEMENT_REQUEST : 'NEW_REMBOURSEMENT_REQUEST',
    NEW_REMBOURSEMENT_SUCCESS : 'NEW_REMBOURSEMENT_SUCCESS',
    NEW_REMBOURSEMENT_RESET : 'NEW_REMBOURSEMENT_RESET',
    NEW_REMBOURSEMENT_FAIL : 'NEW_REMBOURSEMENT_FAIL',
   
    DELETE_REMBOURSEMENT_REQUEST : 'DELETE_REMBOURSEMENT_REQUEST',
    DELETE_REMBOURSEMENT_SUCCESS : 'DELETE_REMBOURSEMENT_SUCCESS',
    DELETE_REMBOURSEMENT_RESET : 'DELETE_REMBOURSEMENT_RESET',
    DELETE_REMBOURSEMENT_FAIL : 'DELETE_REMBOURSEMENT_FAIL',

    UPDATE_REMBOURSEMENT_REQUEST : 'UPDATE_REMBOURSEMENT_REQUEST',
    UPDATE_REMBOURSEMENT_SUCCESS : 'UPDATE_REMBOURSEMENT_SUCCESS',
    UPDATE_REMBOURSEMENT_RESET : 'UPDATE_REMBOURSEMENT_RESET',
    UPDATE_REMBOURSEMENT_FAIL : 'UPDATE_REMBOURSEMENT_FAIL',
   
    REMBOURSEMENT_MACHINE_REQUEST : 'REMBOURSEMENT_MACHINE_REQUEST',
    REMBOURSEMENT_MACHINE_SUCCESS : 'REMBOURSEMENT_MACHINE_SUCCESS',
    REMBOURSEMENT_MACHINE_FAIL : 'REMBOURSEMENT_MACHINE_FAIL',

    REMBOURSEMENT_DETAILS_REQUEST : 'REMBOURSEMENT_DETAILS_REQUEST',
    REMBOURSEMENT_DETAILS_SUCCESS : 'REMBOURSEMENT_DETAILS_SUCCESS',
    REMBOURSEMENT_DETAILS_FAIL : 'REMBOURSEMENT_DETAILS_FAIL',
   
    CLEAR_ERRORS : 'CLEAR_ERRORS',
 }

