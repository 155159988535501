import {allocproductsConstants} from '../../constants/allocproductConstants'

export const allocproductsReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case allocproductsConstants.ALL_PRODUCTS_MACHINE_REQUEST:
        // case allocproductsConstants.ADMIN_MACHINE_REQUEST:
            return {
                loading: true,
                products: []
            }

        case allocproductsConstants.ALL_PRODUCTS_MACHINE_SUCCESS:
            return {
                loading: false,
                products: action.payload.products,
                productsCount: action.payload.productsCount,
                // resPerPage: action.payload.resPerPage,
                // filteredmachinesCount: action.payload.filteredmachinesCount
            }

        // case allocproductsConstants.ADMIN_MACHINE_SUCCESS:
        //     return {
        //         loading: false,
        //         machines: action.payload
        //     }

        case allocproductsConstants.ALL_PRODUCTS_MACHINE_FAIL:
        // case allocproductsConstants.ADMIN_MACHINE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case allocproductsConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const allocProductByMachineReducer = (state = { alloc_products: [] }, action) => {
    switch (action.type) {
        case allocproductsConstants.PRODUCTS_MACHINE_REQUEST:
        // case maintenanceConstants.ADMIN_MAINTENANCE_REQUEST:
            return {
                loading: true,
                alloc_products: []
            }

        case allocproductsConstants.PRODUCTS_MACHINE_SUCCESS:
            return {
                loading: false,
                alloc_products: action.payload.alloc_products,
                // productsCount: action.payload.productsCount,
                // resPerPage: action.payload.resPerPage,
                // filteredproductsCount: action.payload.filteredproductsCount
            }

        // case maintenanceConstants.ADMIN_MAINTENANCE_SUCCESS:
        //     return {
        //         loading: false,
        //         products: action.payload
        //     }

        case allocproductsConstants.PRODUCTS_MACHINE_FAIL:
        // case maintenanceConstants.ADMIN_MAINTENANCE_FAIL:
            return {
                loading: false,
                error: action.payload.error
            }

        case allocproductsConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const newAllocProductReducer = (state = { product: {} }, action) => {
    switch (action.type) {

        case allocproductsConstants.NEW_PRODUCTS_MACHINE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case allocproductsConstants.NEW_PRODUCTS_MACHINE_SUCCESS:
            return {
                loading: false,
                success: action.payload.success,
                // machine: action.payload.machine,
                // token: action.payload.token
            }

        case allocproductsConstants.NEW_PRODUCTS_MACHINE_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        case allocproductsConstants.NEW_PRODUCTS_MACHINE_RESET:
            return {
                ...state,
                success: false
            }

        case allocproductsConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const allocproductReducer = (state = {}, action) => {
    switch (action.type) {

        case allocproductsConstants.DELETE_PRODUCTS_MACHINE_REQUEST:
        // case allocproductsConstants.UPDATE_PRODUCTS_MACHINE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case allocproductsConstants.DELETE_PRODUCTS_MACHINE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload.isDeleted
            }

        // case allocproductsConstants.UPDATE_PRODUCTS_MACHINE_SUCCESS:
        //     return {
        //         ...state,
        //         loading: false,
        //         isUpdated: action.payload.isUpdated
        //     }


        case allocproductsConstants.DELETE_PRODUCTS_MACHINE_FAIL:
        // case allocproductsConstants.UPDATE_PRODUCTS_MACHINE_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        // case allocproductsConstants.DELETE_PRODUCTS_MACHINE_RESET:
        //     return {
        //         ...state,
        //         isDeleted: false
        //     }

        // case allocproductsConstants.UPDATE_PRODUCTS_MACHINE_RESET:
        //     return {
        //         ...state,
        //         isUpdated: false
        //     }

        case allocproductsConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

export const allocproductDetailsReducer = (state = { product: {} }, action) => {
    switch (action.type) {

        case allocproductsConstants.PRODUCTS_MACHINE_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case allocproductsConstants.PRODUCTS_MACHINE_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload.product
            }

        case allocproductsConstants.PRODUCTS_MACHINE_DETAILS_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        case allocproductsConstants.CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}
