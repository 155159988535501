export const categoriesConstants = {

 ALL_CATEGORY_REQUEST : 'ALL_CATEGORY_REQUEST',
 ALL_CATEGORY_SUCCESS : 'ALL_CATEGORY_SUCCESS',
 ALL_CATEGORY_FAIL : 'ALL_CATEGORY_FAIL',

 NEW_CATEGORY_REQUEST : 'NEW_CATEGORY_REQUEST',
 NEW_CATEGORY_SUCCESS : 'NEW_CATEGORY_SUCCESS',
 NEW_CATEGORY_RESET : 'NEW_CATEGORY_RESET',
 NEW_CATEGORY_FAIL : 'NEW_CATEGORY_FAIL',

 DELETE_CATEGORY_REQUEST : 'DELETE_CATEGORY_REQUEST',
 DELETE_CATEGORY_SUCCESS : 'DELETE_CATEGORY_SUCCESS',
 DELETE_CATEGORY_RESET : 'DELETE_CATEGORY_RESET',
 DELETE_CATEGORY_FAIL : 'DELETE_CATEGORY_FAIL',

 UPDATE_CATEGORY_REQUEST : 'UPDATE_CATEGORY_REQUEST',
 UPDATE_CATEGORY_SUCCESS : 'UPDATE_CATEGORY_SUCCESS',
 UPDATE_CATEGORY_RESET : 'UPDATE_CATEGORY_RESET',
 UPDATE_CATEGORY_FAIL : 'UPDATE_CATEGORY_FAIL',

 CATEGORY_DETAILS_REQUEST : ' CATEGORY_DETAILS_REQUEST',
 CATEGORY_DETAILS_SUCCESS : ' CATEGORY_DETAILS_SUCCESS',
 CATEGORY_DETAILS_FAIL : ' CATEGORY_DETAILS_FAIL',

 CLEAR_ERRORS : 'CLEAR_ERRORS'
}