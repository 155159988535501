import React, { Component,Fragment } from 'react'
import Breadcrumb from '../common/breadcrumb';
import BeatLoader from "react-spinners/BeatLoader";
import {connect} from "react-redux";
import { withRouter, Redirect, Router } from 'react-router-dom';
import * as settingsActions from "../../redux/actions/settingsActions";
import { ToastContainer, toast } from 'react-toastify';

export class Change_password extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            isLoading: false,
            loading: false,
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: ''
        }

        this.handleInputChange = this.handleInputChange.bind(this)
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    componentDidMount = () => {
        this.setState({
            loading: true
        })
        setTimeout(() => {
            this.setState({
                loading: false
            })
        },1000)
    }

    handleSubmitChange = (e) => {
        e.preventDefault();
        this.setState({
            isLoading: true,
        })
        
        if(this.state.newPassword === this.state.newPasswordRepeat){
            this.props.changePassword(this.state);
       
            setTimeout(()=> {
                this.setState({
                    isLoading: false,
                    oldPassword: '',
                    newPassword: '',
                    newPasswordRepeat: ''
                })                
            }, 1000)
        }else {
            this.props.history.push('/settings/change-password');
            toast.error("Nouveaux mots de passe non-identiques!");
            this.setState ({
                isLoading: false,
                oldPassword: '',
                newPassword: '',
                newPasswordRepeat: ''
            })
        }

    }
    render() {
        const {isLoading, oldPassword, newPassword, newPasswordRepeat} = this.state
        if(this.state.loading){
            return(
                <div style={{display: "flex", justifyContent: "center", 
                            alignItems: "center", width: "100%", height: "100vh"}}>
                   <BeatLoader color={"#EC1C5B"} loading={this.state.loading} size={50} />
                </div>
            )
        }else{
            return (
                <Fragment>
                    <Breadcrumb title="Modifier le mot de passe" parent="Paramètres" />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5> Modifier le mot de passe</h5>
                                    </div>
                                    <div className="card-body">
                                        <form className="needs-validation">
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Ancien mot de passe :</label>
                                                <input className="form-control col-xl-8 col-md-7" 
                                                    id="validationCustom0" 
                                                    name="oldPassword"
                                                    type="password" 
                                                    value={oldPassword}
                                                    onChange={this.handleInputChange}
                                                    required="" 
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Nouveau mot de passe :</label>
                                                <input className="form-control col-xl-8 col-md-7" 
                                                    id="validationCustom2" 
                                                    name="newPassword"
                                                    value={newPassword}
                                                    onChange={this.handleInputChange}
                                                    type="password" 
                                                    required="" 
                                                />
                                            </div>
                                            <div className="form-group row">
                                                <label className="col-xl-3 col-md-4"><span>*</span> Repéter le nouveau mot de passe :</label>
                                                <input className="form-control col-xl-8 col-md-7" 
                                                    id="validationCustom2" 
                                                    name="newPasswordRepeat"
                                                    value={newPasswordRepeat}
                                                    onChange={this.handleInputChange}
                                                    type="password" 
                                                    required="" 
                                                />
                                            </div>
                                            <div className="offset-xl-3 offset-sm-4">
                                                <button type="button" 
                                                className="btn btn-primary"
                                                disabled={isLoading}
                                                onClick={this.handleSubmitChange}
                                                >
                                                Enregister</button>
                                            </div>
                                            <ToastContainer />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }
}
const mapStateToProps = (state) => {
    return {
        changepassword: state.changepassword
    }
}
const mapDispatchToProps = (dispatch) =>{
    return {
        changePassword: (password) => {dispatch(settingsActions.changePassword(password))}
    }
}

export default  connect(mapStateToProps, mapDispatchToProps) (withRouter(Change_password))
