import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom'
import { ChevronLeft, ChevronRight, Edit, Trash2 } from 'react-feather'
import { Navigation, Box, MessageSquare, Users, Briefcase, CreditCard, ShoppingCart, Calendar } from 'react-feather';
import BeatLoader from "react-spinners/BeatLoader";
import { Chart } from "react-google-charts";
import { ToastContainer, toast } from 'react-toastify';
import { connect } from "react-redux";
import Breadcrumb from '../common/breadcrumb';
import data from '../../assets/data/listUser';
import Datatable from '../common/datatable'
import Data_machines from '../common/dataTables/data_machines';
import * as machineActions from "../../redux/actions/machineActions";
import * as roleActions from "../../redux/actions/roleActions";
import {
    pieOptions,
    doughnutOption,
    lineOptions,
    buyOption,
    employeeData,
    employeeOptions
} from '../../constants/chartData'

import Slider from "react-slick"
import styles from "./list-machine.module.css"

const PrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div style={{backgroundColor: "#ffffffa1", position: "absolute", top: "50%", left: "1%", zIndex: 1, padding: "18px", borderRadius: "100%"}}>
            <ChevronLeft color='#EC1C5B' size={32} onClick={onClick} style={{ ...style }}/>
            {/* <ChevronLeft 
                className={className} 
                style={{ ...style, display: "block" }} 
                onClick={onClick} /> */}
        </div>
    );
}

const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div style={{backgroundColor: "#ffffffa1", position: "absolute", top: "50%", right: "1%", zIndex: 1, padding: "18px", borderRadius: "100%"}}>
            <ChevronRight color='#EC1C5B' size={32} onClick={onClick} style={{ ...style }}/>
            {/* <ChevronLeft 
                className={className} 
                style={{ ...style, display: "block" }} 
                onClick={onClick} /> */}
        </div>
    );
}

export class List_machine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            machines: [],
            roles: null,
            visible: false,
            loading: false,
        };
        
    }

    componentDidMount = () => {
        let listMachines = [];
        let listeMachine = null;

        this.setState({
            loading: true
        })
        this.props.machines();

        setTimeout(() => {

            this.props.machine.machines.map(machine => {
                console.log('machine', machine)
                let item = {
                    ID: machine.id,
                    Nom: machine.nom,
                    Type: machine.type,
                    Status: machine.status,
                    Localisation: machine.localisation,
                    Montant: machine.montant,
                    lien: machine.lien,
                    image: machine.filePath,
                    statut: machine.status,
                    etat: machine.etat,
                    description: machine.description
                }

                listMachines.push(item);
            })
            this.setState({
                machines: listMachines,
            })

        }, 1000)

        this.props.actionsdetailRole(localStorage.getItem('roles'))

        setTimeout(() => {
            if (this.props.roledetails.role) {
                this.props.roledetails.role.map(rl => {
                    listeMachine = rl.listeMachine
                })
                if (this.props.roledetails.role[0].ajouterMachine == '1') {
                    this.setState({
                        visible: true
                    })
                }
                this.setState({
                    roles: listeMachine,
                    loading: false,
                })
            }
        }, 1000)

    }

    render() {
        const { machines, visible, roles, loading, count } = this.state
        // console.log('machine state', machines)
        // console.log('taile', machines)
        const LineOptions = {
            hAxis: {
                textPosition: 'none', baselineColor: 'transparent',
                gridlineColor: 'transparent',
            },
            vAxis: {
                textPosition: 'none', baselineColor: 'transparent',
                gridlineColor: 'transparent',
            },
            colors: ['#ff8084'],
            legend: 'none',
        }

        const settings = {
            dots: true,
            arrows: true,
            accessibility: true,
            className: "center",
            centerMode: true,
            centerPadding: "60px",
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            speed: 500,
            autoplaySpeed: 6000,
            pauseOnHover: true,
            cssEase: "linear",
            adaptiveHeight: true,
            swipeToSlide: true,
            draggable: true,
            focusOnSelect: true,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 512,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ],
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        }

        // console.log('mach',machines)
        if (loading) {
            return (
                <div style={{
                    display: "flex", justifyContent: "center",
                    alignItems: "center", width: "100%", height: "100vh"
                }}>
                    <BeatLoader color={"#EC1C5B"} loading={loading} size={50} />
                </div>
            )
        } else {
            if (roles === '1') {
                return (
                    <Fragment>
                        <Breadcrumb title="Liste des machines" parent="Machines" />
                        <div className="container-fluid">
                            <div className="card">
                                {visible === true ?
                                    (
                                        <div className="card-header">
                                            <Link to="/machines/create-machine" className="btn btn-primary">Ajout de Machine</Link>
                                        </div>
                                    )
                                    : null
                                }
                            </div>

                            <Slider {...settings}>
                                {
                                    machines.map((machine, i) => {
                                        console.log('status', machine.Status)
                                        return (
                                            <div className={styles.slider_element} key={i}>
                                                <Link to={`/machines/machine-details/${machine.ID}`}>
                                                    <div className="card">
                                                        <div className="products-admin">
                                                            <div className="card-header product-box">
                                                                <div className="row">
                                                                    <div className="lable-block">
                                                                        {(machine.Status === "ON") ?
                                                                            <span className="list-machine lable3">{machine.Status}</span>
                                                                            : (machine.Status === "OFF") ? <span className="list-machine lable31">{machine.Status}</span> :
                                                                                <span className="list-machine lable32">{machine.Status}</span>}
                                                                        {/* {(machine.discount === 'on sale' )?<span className="lable4">{machine.discount}</span> : '' } */}
                                                                    </div>
                                                                    <div className="col-9">
                                                                        {/* <div className="small-chartjs"> */}
                                                                        <div className="front">
                                                                            {/* <a className="bg-size"> */}
                                                                            <img className="img-fluid blur-up bg-img lazyloaded" src={machine.image} alt='' />
                                                                            {/* </a> */}
                                                                            <div className="product-hover">
                                                                                <ul>
                                                                                    <li>
                                                                                        <Link className="btn" type="button"
                                                                                            to={`/machines/edit-machine/${machine.ID}`}>
                                                                                            <Edit className="editBtn" />
                                                                                        </Link>
                                                                                    </li>
                                                                                    {/* <li>
                                                                                        <button className="btn" 
                                                                                            type="button"
                                                                                            // onClick={(e) =>{
                                                                                            //     if (window.confirm('Are you sure you wish to delete this item?'))
                                                                                            //         // this.handleDeleteProduct(machine.ID)
                                                                                            //         setTimeout(() => {
                                                                                            //             window.location.reload()
                                                                                            //         },1000)
                                                                                            // }}
                                                                                            ><Trash2 className="deleteBtn" />
                                                                                        </button>
                                                                                    </li> */}
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </div>
                                                                    {/* <div className="col-6">
                                                                    <div className="value-graph">
                                                                        <h3>42% <span><i className="fa fa-angle-up font-primary"></i></span></h3>
                                                                    </div>
                                                                </div> */}
                                                                </div>
                                                            </div>
                                                            <div className="card-body m-0">
                                                                <div className="media">
                                                                    <div className="media-body">
                                                                        <h3>{machine.Nom}</h3>
                                                                        <span>{machine.Localisation}</span>
                                                                        <h2 className="mb-1">{machine.Montant}</h2>
                                                                        {/* <p>{machine.statut} <span><i className="fa fa-angle-up"></i></span></p> */}
                                                                        <h5 className="f-w-600 f-16">{machine.Type}</h5>
                                                                        <p>{machine.description}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>

                    </Fragment>
                )
            }
        }

    }
}

const mapStateToProps = (state) => {
    return {
        machine: state.machine,
        roledetails: state.roledetails
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        machines: () => { dispatch(machineActions.machines()) },
        actionsdetailRole: (rolename) => { dispatch(roleActions.actionsdetailRole(rolename)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(List_machine)
