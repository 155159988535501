import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import axios from "axios";
import * as roleActions from "../../../redux/actions/roleActions";
import * as allocslideActions from "../../../redux/actions/allocslideActions"

export class Data_slides_Alloc extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            myData: this.props.myData,
            slideSelectedId: 0,
            loading: false
        }
    }

    selectRow = (e, id) => {
        console.log({ slide: id })
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item) => id !== item)
            });
        } else {
            this.state.checkedValues.push(id);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleAllocMultipleRows = () => {
        const selectedValues = this.state.checkedValues;
        const token = localStorage.getItem('token');
        let config = {
            headers: {
                'USER-KEY': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }
        this.setState({ loading: true })
        selectedValues.map(_ => {
            let alloc_slide = {
                machine_id: this.props.machine_id,
                slide_id: _
            }
            this.props.newAllocSlide(alloc_slide)
        })
        if (this.props.addslideinmachine.success) {
            this.props.history.push('/machines/machie-details/' + this.props.machine_id)
        } else this.props.history.push('/machines/add-slide/machine/' + this.props.machine_id)

        // for (var i = 0; i < selectedValues.length; i++) {
        //     axios.delete(`slide/${selectedValues[i]}`, config)
        //         .then(() => {
        //             axios.get('/slides')
        //                 .then((response) => {
        //                     const { slides } = response.data
        //                     this.setState({
        //                         myData: slides
        //                     })
        //                 })
        //         })
        // }
        // window.location.reload()
        toast.success("Successfully Allocated !")
    };

    handleAllocSingleRow = () => {
        let alloc_slide = {
            machine_id: this.props.machine_id,
            slide_id: this.state.slideSelectedId
        }
        this.props.newAllocSlide(alloc_slide)
        if (this.props.addslideinmachine.success) {
            this.props.history.push('/machines/machie-details/' + this.props.machine_id)
        } else this.props.hitory.push('/machines/add-slide/machine/' + this.props.machine_id)

        // const token = localStorage.getItem('token');
        // let config = {
        //     headers: {
        //         'USER-KEY': `Bearer ${token}`,
        //         'Content-Type': 'application/json'
        //     }
        // }
        // axios.delete(`slide/${this.state.slideSelectedId}`, config)
        //     .then(() => {
        //         axios.get('/slides')
        //             .then((response) => {
        //                 const { slides } = response.data
        //                 this.setState({
        //                     myData: slides
        //                 })
        //             })
        //     })
        // window.location.reload()
        toast.success("Successfully Allocated !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    render() {
        const { pageSize, myClass, check, multiSelectOption, pagination } = this.props;
        // const { myData, deletable, updatable } = this.state
        const { myData } = this.state

        const columns = [];
        for (var key in myData[0]) {

            let editable = this.renderEditable
            if (key === "image") {
                editable = null;
            }
            if (key === "status") {
                editable = null;
            }
            if (key === "avtar") {
                editable = null;
            }
            if (key === "vendor") {
                editable = null;
            }
            if (key === "order_status") {
                editable = null;
            }

            columns.push(
                {
                    Header: <b>{this.Capitalize(key.toString())}</b>,
                    accessor: key,
                    Cell: editable,
                    style: {
                        textAlign: 'center'
                    }
                });
        }


        if (multiSelectOption == true) {
                columns.push(
                    {
                        Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                            onClick={() => this.handleAllocMultipleRows()}>Affecter</button>,
                        id: 'affecter',
                        accessor: str => "affecter",
                        sortable: false,
                        style: {
                            textAlign: 'center'
                        },
                        Cell: (row) => (
                            <div>
                                <span >
                                    <input type="checkbox" name={row.original.id}
                                        defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                        onChange={e => this.selectRow(e, row.original.id)} />
                                </span>
                            </div>

                        ),
                        accessor: key,
                        style: {
                            textAlign: 'center'
                        }
                    }
                )

        } else {
            columns.push(
                {
                    Header: <b>Action</b>,
                    id: 'affecter',
                    accessor: str => "affecter",
                    Cell: (row) => (
                        <div>
                            <span onClick={() => this.handleAllocSingleRow(row.original.id)}>
                                <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }}
                                ></i>
                            </span>
                        </div>
                    ),
                    style: {
                        textAlign: 'center'
                    },
                    sortable: false
                }
            )
        }

        return (
            <Fragment>
                <ReactTable
                    data={myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
                <ToastContainer />
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roledetails: state.roledetails,
        addslideinmachine: state.addslideinmachine
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actionsdetailRole: (rolename) => { dispatch(roleActions.actionsdetailRole(rolename)) },
        newAllocSlide: (slide) => { dispatch(allocslideActions.newAllocSlide(slide)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Data_slides_Alloc)
