export const slideConstants = {
    ADMIN_SLIDES_REQUEST : 'ADMIN_SLIDES_REQUEST',
    ADMIN_SLIDES_SUCCESS : 'ADMIN_SLIDES_SUCCESS',
    ADMIN_SLIDES_FAIL : 'ADMIN_SLIDES_FAIL',

    ALL_SLIDES_REQUEST : 'ALL_SLIDES_REQUEST',
    ALL_SLIDES_SUCCESS : 'ALL_SLIDES_SUCCESS',
    ALL_SLIDES_FAIL : 'ALL_SLIDES_FAIL',

    NEW_SLIDE_REQUEST : 'NEW_SLIDE_REQUEST',
    NEW_SLIDE_SUCCESS : 'NEW_SLIDE_SUCCESS',
    NEW_SLIDE_RESET : 'NEW_SLIDE_RESET',
    NEW_SLIDE_FAIL : 'NEW_SLIDE_FAIL',

    DELETE_SLIDE_REQUEST : 'DELETE_SLIDE_REQUEST',
    DELETE_SLIDE_SUCCESS : 'DELETE_SLIDE_SUCCESS',
    DELETE_SLIDE_RESET : 'DELETE_SLIDE_RESET',
    DELETE_SLIDE_FAIL : 'DELETE_SLIDE_FAIL',

    UPDATE_SLIDE_REQUEST : 'UPDATE_SLIDE_REQUEST',
    UPDATE_SLIDE_SUCCESS : 'UPDATE_SLIDE_SUCCESS',
    UPDATE_SLIDE_RESET : 'UPDATE_SLIDE_RESET',
    UPDATE_SLIDE_FAIL : 'UPDATE_SLIDE_FAIL',

    SLIDE_DETAILS_REQUEST : 'SLIDE_DETAILS_REQUEST',
    SLIDE_DETAILS_SUCCESS : 'SLIDE_DETAILS_SUCCESS',
    SLIDE_DETAILS_FAIL : 'SLIDE_DETAILS_FAIL',

    CLEAR_ERRORS : 'CLEAR_ERRORS'

}