import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Edit, Trash2, MinusCircle, PlusCircle } from 'react-feather'
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { connect } from "react-redux";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import Breadcrumb from '../../common/breadcrumb';
import * as productActions from "../../../redux/actions/productActions";
import * as roleActions from "../../../redux/actions/roleActions";

export class Product_list extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            products: [],
            visible: false,
            roles: null,
            deletable: false,
            updatable: false,
            loading: false,
            hiddenDeactivateDialog: true,
            hiddenActivateDialog: true,
            productTouchedId: 0
        };

        // this.handleDeleteProduct = this.handleDeleteProduct.bind(this)
        this.handleDeactivateProduct = this.handleDeactivateProduct.bind(this)
        this.handleDeactivateDialog = this.handleDeactivateDialog.bind(this)

    }

    modalPropsStyles = { main: { maxWidth: 450 } };
    deactivateDialogContentProps = {
        type: DialogType.normal,
        title: 'Désactivation de produit',
        subText: 'Voulez vraiment désactiver globalement ce produit? Cette action supprimera toutes les affectations de machine liées à ce produit',
    };
    activateDialogContentProps = {
        type: DialogType.normal,
        title: 'Activation de produit',
        subText: 'Voulez vraiment activer globalement ce produit?',
    };

    toggleDeactivateDialog = () => {
        this.setState(prevState => ({
            hiddenDeactivateDialog: !prevState.hiddenDeactivateDialog
        }));
    }

    handleDeactivateDialog = (productId) => {
        console.log({ productId })
        this.setState(state => ({ ...state, productTouchedId: productId }))
        setTimeout(() => {
            this.toggleDeactivateDialog()
            console.log({ productTouchedId: this.state.productTouchedId })
        }, 100)
    }

    toggleActivateDialog = () => {
        this.setState(prevState => ({
            hiddenActivateDialog: !prevState.hiddenActivateDialog
        }));
    }

    handleActivateDialog = (productId) => {
        console.log({ productId })
        this.setState(state => ({ ...state, productTouchedId: productId }))
        setTimeout(() => {
            this.toggleActivateDialog()
            console.log({ productTouchedId: this.state.productTouchedId })
        }, 100)
    }

    handleDeactivateProduct = () => {
        console.log({ productTouchedId: this.state.productTouchedId })
        this.props.deactivateProduct(this.state.productTouchedId);
        this.toggleDeactivateDialog()
        toast.success("Successfully Deactivated !")
        setTimeout(() => window.location.reload(), 100)
    }

    handleActivateProduct = () => {
        console.log({ productTouchedId: this.state.productTouchedId })
        this.props.activateProduct(this.state.productTouchedId);
        this.toggleActivateDialog()
        toast.success("Successfully Activated !")
        setTimeout(() => window.location.reload(), 100)
    }

    componentDidMount = () => {
        let listProducts = [];
        let rol = null;

        this.setState({
            loading: true
        })
        this.props.products();

        setTimeout(() => {
            // console.log(this.props.product.products)

            this.props.product.products.map(product => {
                let item = {
                    product_id: product.product_id,
                    image: product.image_urls[0],
                    Nom: product.name,
                    unit: product.unit,
                    reference: product.reference,
                    description: product.description,
                    etat: product.etat,
                    categories: product.categories[0],
                    status: product.status ? parseInt(product.status) : 1
                }
                listProducts.push(item);
            })
            const sortedProducts = [...listProducts].sort((a, b) => b.status - a.status)
            console.log({ sortedProducts })
            this.setState({
                products: sortedProducts
            })
        }, 1000)

        this.props.actionsdetailRole(localStorage.getItem('roles'))

        setTimeout(() => {
            this.props.roledetails.role.map(rl => {
                rol = rl.listeProduit;
            })
            this.setState({
                roles: rol
            })
            if (this.props.roledetails.role[0].ajouterProduit == '1') {
                this.setState({
                    visible: true
                })
            }
            if (this.props.roledetails.role[0].modifierProduit == '1') {
                this.setState({
                    updatable: true
                })
            }
            if (this.props.roledetails.role[0].supprimerProduit == '1') {
                this.setState({
                    deletable: true
                })
            }
            this.setState({
                loading: false
            })
        }, 1000)

    }

    render() {
        const { loading, currencies, roles, visible, deletable, updatable } = this.state

        if (loading) {
            return (
                <div style={{
                    display: "flex", justifyContent: "center",
                    alignItems: "center", width: "100%", height: "100vh"
                }}>
                    <BeatLoader color={"#EC1C5B"} loading={loading} size={50} />
                </div>
            )
        } else {
            if (roles === '1') {
                return (
                    <Fragment>
                        <Breadcrumb title="Liste des Produits" parent="Produits" />
                        <div className="container-fluid">
                            <div className="card">
                                {visible === true ?
                                    (
                                        <div className="card-header">
                                            <Link type="button" to="/products/physical/add-product" className="btn btn-primary">Ajouter un Produit</Link>
                                        </div>
                                    ) :
                                    null
                                }
                            </div>
                            <div className="row products-admin ratio_asos">
                                <ToastContainer />
                                {

                                    this.state.products.map((myData, i) => {
                                        return (
                                            <div className="col-xl-3 col-sm-6" key={i}>
                                                <div className="card">
                                                    <div className="products-admin">
                                                        {myData.status === 0 ?
                                                            <div className='product-deactivate'></div>
                                                            :
                                                            <Fragment />
                                                        }
                                                        <div className="card-body product-box">
                                                            <div className="img-wrapper">
                                                                {/* <div className="lable-block">
                                                                    {(myData.quantite)?((myData.quantite< 4)?
                                                                    <span className="lable31">{myData.quantite}</span>
                                                                    :<span className="lable3">{myData.quantite}</span>) : 
                                                                    <span className="lable32">{myData.quantite}</span>}
                                                                    {(myData.discount === 'on sale' )?<span className="lable4">{myData.discount}</span> : '' }
                                                                </div> */}
                                                                <div className="front">
                                                                    <a className="bg-size"><img className="img-fluid blur-up bg-img lazyloaded" src={myData.image} alt='' /></a>
                                                                    {myData.status === 0 ?
                                                                        <div className='product-deactivate'></div>
                                                                        :
                                                                        <Fragment />
                                                                    }
                                                                    <div className="product-hover">
                                                                        {deletable === true && updatable === true ?
                                                                            (
                                                                                <ul>
                                                                                    <li>
                                                                                        <Link className="btn" type="button"
                                                                                            to={`/products/physical/edit-products/${myData.product_id}`}>
                                                                                            <Edit className="editBtn" />
                                                                                        </Link>
                                                                                    </li>
                                                                                    <li>
                                                                                        {myData.status === 0 ?
                                                                                            <button className="btn"
                                                                                                type="button"
                                                                                                onClick={() => this.handleActivateDialog(myData.product_id)}
                                                                                            ><PlusCircle className="deactivateBtn" />
                                                                                            </button>
                                                                                            :
                                                                                            <button className="btn"
                                                                                                type="button"
                                                                                                onClick={() => this.handleDeactivateDialog(myData.product_id)}
                                                                                            ><MinusCircle className="deactivateBtn" />
                                                                                            </button>
                                                                                        }
                                                                                    </li>
                                                                                </ul>
                                                                            ) : deletable === false && updatable === true ?
                                                                                (
                                                                                    <ul>
                                                                                        <li>
                                                                                            <Link className="btn" type="button"
                                                                                                to={`/products/physical/edit-products/${myData.product_id}`}>
                                                                                                <Edit className="editBtn" />
                                                                                            </Link>
                                                                                        </li>
                                                                                    </ul>
                                                                                ) : deletable === true && updatable === false ?
                                                                                    (
                                                                                        <ul>
                                                                                            <li>
                                                                                                {myData.status === 0 ?
                                                                                                    <button className="btn"
                                                                                                        type="button"
                                                                                                        onClick={() => this.handleActivateDialog(myData.product_id)}
                                                                                                    ><PlusCircle className="deactivateBtn" />
                                                                                                    </button>
                                                                                                    :
                                                                                                    <button className="btn"
                                                                                                        type="button"
                                                                                                        onClick={() => this.handleDeactivateDialog(myData.product_id)}
                                                                                                    ><MinusCircle className="deactivateBtn" />
                                                                                                    </button>
                                                                                                }
                                                                                            </li>
                                                                                        </ul>
                                                                                    ) :
                                                                                    null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="product-detail">
                                                                <span> <h6 >{myData.Nom}</h6></span>
                                                                <span>{myData.unit} </span><br />
                                                                <span>Ref: {myData.reference}</span><br />
                                                                <span>{myData.description}</span>
                                                                {/* <div className="rating">
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                    <i className="fa fa-star"></i>
                                                                </div>
                                                                <a> <h6 >{myData.title}</h6></a>
                                                                <h4 >{myData.price} <del >{myData.discount_price}</del></h4>
                                                                <ul className="color-variant">
                                                                    <li className="bg-light0"></li>
                                                                    <li className="bg-light1"></li>
                                                                    <li className="bg-light2"></li>
                                                                </ul> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='product-backdrop'></div> */}
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        <Dialog
                            hidden={this.state.hiddenDeactivateDialog}
                            onDismiss={() => this.toggleDeactivateDialog()}
                            dialogContentProps={this.deactivateDialogContentProps}
                            isBlocking={true}
                        // modalProps={modalProps}
                        >
                            <DialogFooter>
                                <PrimaryButton onClick={() => this.toggleDeactivateDialog()} text="Annuler" />
                                <DefaultButton onClick={() => this.handleDeactivateProduct()} text="Déactiver" style={{ backgroundColor: '#dc3545', color: '#fff' }} />
                            </DialogFooter>
                        </Dialog>

                        <Dialog
                            hidden={this.state.hiddenActivateDialog}
                            onDismiss={() => this.toggleActivateDialog()}
                            dialogContentProps={this.activateDialogContentProps}
                            isBlocking={true}
                        >
                            <DialogFooter>
                                <DefaultButton onClick={() => this.toggleActivateDialog()} text="Annuler" style={{ backgroundColor: '#dc3545', color: '#fff' }} />
                                <PrimaryButton onClick={() => this.handleActivateProduct()} text="Activer" />
                            </DialogFooter>
                        </Dialog>
                    </Fragment>
                )
            } else {
                return (
                    <Fragment>

                    </Fragment>
                )
            }
        }
    }
}

const mapStateToProps = (state) => {
    return {
        product: state.product,
        productaction: state.productaction,
        roledetails: state.roledetails
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        products: () => { dispatch(productActions.products()) },
        deleteProduct: (productID) => { dispatch(productActions.deleteProduct(productID)) },
        deactivateProduct: (productID) => { dispatch(productActions.deactivateProduct(productID)) },
        activateProduct: (productID) => { dispatch(productActions.activateProduct(productID)) },
        actionsdetailRole: (rolename) => { dispatch(roleActions.actionsdetailRole(rolename)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Product_list))
