import React, { Component, Fragment } from 'react';
import Breadcrumb from '../common/breadcrumb';
import CKEditors from "react-ckeditor-component";
import { Link } from "react-router-dom";
import { AvField, AvForm } from 'availity-reactstrap-validation';
import one from '../../assets/images/pro3/1.jpg'
import user from '../../assets/images/user.png';
import * as machineActions from "../../redux/actions/machineActions";
import * as allocproductActions from "../../redux/actions/allocproductActions";
import { connect } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import * as productActions from '../../redux/actions/productActions';
import Select from 'react-select';
import { withRouter, Redirect, Router } from 'react-router-dom';
import * as roleActions from "../../redux/actions/roleActions";
import BeatLoader from "react-spinners/BeatLoader";
import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import AddAllocProductForm from './AddAllocProductForm.component';


export class AddProduct_machine extends Component {

    constructor(props) {
        super(props)

        this.state = {
            products: [],
            isLoading: false,
            visible: false,
            roles: null,
            loading: false,
            machineId: this.props.match.params.id
        }
    }


    // handleSubmitChange = (e) => {
    //     e.preventDefault();
    //     this.setState({
    //         isLoading: true,

    //     })
    //     // // console.log(this.state.AllOptions)
    //     this.props.newAllocProduct(this.state);

    //     setTimeout(() => {
    //         console.log(this.props.addproductinmachine.success)
    //         if (this.props.addproductinmachine.success === true) {
    //             this.props.history.push('/machines/machine-details/' + this.state.machineId);
    //         } else {
    //             this.props.history.push('/machines/add-product/machine/' + this.state.machineId);
    //             this.setState({
    //                 isLoading: false
    //             })
    //         }
    //     }, 1000)
    // }


    componentDidMount = () => {
        let listAllocProducts = []
        let products = []
        let link = process.env.REACT_APP_NEXT_PUBLIC_REST_API
        //     let rol = null;

        this.setState({
            loading: true
        })

        //     this.props.actionsdetailRole(localStorage.getItem('roles'))

        //     setTimeout(() => {
        //         this.props.roledetails.role.map(rl => {
        //             rol = rl.ajouterProduit;
        //         })
        //         this.setState({
        //             roles: rol
        //         })
        //         if(this.props.roledetails.role[0].listeProduit == '1'){
        //             this.setState({
        //                 visible: true
        //             })
        //         }
        //     }, 1000)

        this.props.allocProductByMachine(this.state.machineId)

        setTimeout(() => {
            // console.log('alloc', this.props.allocproductbymachine)
            this.props.allocproductbymachine.alloc_products.map(alloc_product => {
                // console.log(link+alloc_product.product.images[0].filePath)
                let item = {
                    alloc_id: alloc_product.id,
                    product_id: alloc_product.product.product_id,
                    image: link + alloc_product.product.images[0].filePath,
                    Nom: alloc_product.product.name,
                    unit: alloc_product.product.unit,
                    reference: alloc_product.product.reference,
                    description: alloc_product.product.description,
                    categories: alloc_product.product.categories[0],
                    quantite: alloc_product.quantite,
                    price: alloc_product.price

                }
                listAllocProducts.push(item);
            })
            // this.setState({
            //     alloc_products: listAllocProducts,

            // })

        }, 1000)

        this.props.products();

        console.log({products})

        setTimeout(() => {
            products = this.props.product.products.map(p => {
                // console.log({p})
                if (p.status !== '0') {
                    let found = listAllocProducts.some(_ => _.product_id === p.product_id)
                    console.log({found})
                    if (!found) {
                        let item = {
                            id: p.product_id,
                            name: p.name,
                            image: p.image_urls[0]
                        }
                        return item
                    }
                }
            })

            console.log({products})
            
            products = products.filter(_ => _ !== undefined)
            
                        console.log({products})

            this.setState({
                loading: false,
                products
            })
        }, 1000)
    }

    render() {

        const { loading, visible, roles, isLoading } = this.state

        const customStyles = {
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
                width: '715px'
            }),
        }
        if (loading) {
            return (
                <div style={{
                    display: "flex", justifyContent: "center",
                    alignItems: "center", width: "100%", height: "100vh"
                }}>
                    <BeatLoader color={"#EC1C5B"} loading={loading} size={50} />
                </div>
            )
        } else {
            return (
                <Fragment>
                    <Breadcrumb title="Affectation d'un produit" parent="Produits" />

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <Link type="button" to={`/machines/machine-details/${this.state.machineId}`} className="btn btn-primary">Retour</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row products-admin ratio_asos">
                            <ToastContainer />
                            {
                                this.state.products.map((myData, i) => {
                                    return (
                                        <div className="col-xl-3 col-sm-6" key={i}>
                                            <div className="card">
                                                <div className="products-admin">
                                                    <div className="card-body product-box">
                                                        <div className="img-wrapper">
                                                            <div className="front">
                                                                <a className="bg-size"><img className="img-fluid blur-up bg-img lazyloaded" src={myData.image} alt='' /></a>
                                                            </div>
                                                        </div>
                                                        <div className="product-detail">
                                                            <a> <h6 >{myData.name}</h6></a>
                                                            <AddAllocProductForm machine_id={this.state.machineId} product_id={myData.id} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </Fragment >
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        product: state.product,
        addproductinmachine: state.addproductinmachine,
        roledetails: state.roledetails,
        allocproductbymachine: state.allocproductbymachine,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        products: () => { dispatch(productActions.products()) },
        newAllocProduct: (product) => { dispatch(allocproductActions.newAllocProduct(product)) },
        allocProductByMachine: (machine_id) => { dispatch(allocproductActions.allocProductByMachine(machine_id)) },
        actionsdetailRole: (rolename) => { dispatch(roleActions.actionsdetailRole(rolename)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AddProduct_machine))
