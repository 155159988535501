import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import 'react-table/react-table.css';
import ReactTable from 'react-table';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import axios from "axios";
import * as roleActions from "../../../redux/actions/roleActions";

export class Data_slides extends Component {
    constructor(props) {
        super(props)
        this.state = {
            checkedValues: [],
            myData: this.props.myData,
            deletable: false,
            updatable: false,
            hiddenDialog: true,
            hiddenDialogSingle: true,
            slideSelectedId: 0
        }
    }

    dialogContentProps = {
        type: DialogType.normal,
        title: "Suppression de(s) slide(s)",
        subText: 'Voulez vraiment supprimer ce(s) slide(s)?',
    };

    dialogContentPropsSingle = {
        type: DialogType.normal,
        title: "Suppression de slide",
        subText: 'Voulez vraiment supprimer ce slide?',
    };

    toggleDialog = () => {
        this.setState(prevState => ({
            hiddenDialog: !prevState.hiddenDialog
        }));
    }

    toggleDialogSingle = () => {
        this.setState(prevState => ({
            hiddenDialogSingle: !prevState.hiddenDialogSingle
        }));
    }

    handleDialogSingle = (slideId) => {
        this.setState({ slideSelectedId: slideId })
        setTimeout(() => {
            this.toggleDialogSingle()
        }, 100)
    }

    selectRow = (e, i) => {
        if (!e.target.checked) {
            this.setState({
                checkedValues: this.state.checkedValues.filter((item, j) => i !== item)
            });
        } else {
            this.state.checkedValues.push(i);
            this.setState({
                checkedValues: this.state.checkedValues
            })
        }
    }

    handleRemoveRow = () => {
        const selectedValues = this.state.checkedValues;
        const token = localStorage.getItem('token');
        let config = {
            headers: {
                'USER-KEY': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }
        for (var i = 0; i < selectedValues.length; i++) {
            axios.delete(`slide/${selectedValues[i]}`, config)
                .then(() => {
                    axios.get('/slides')
                        .then((response) => {
                            const { slides } = response.data
                            this.setState({
                                myData: slides
                            })
                        })
                })
        }
        this.toggleDialog()
        window.location.reload()
        toast.success("Successfully Deleted !")
    };

    handleRemoveRowSingle = () => {
        const token = localStorage.getItem('token');
        let config = {
            headers: {
                'USER-KEY': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        }
        axios.delete(`slide/${this.state.slideSelectedId}`, config)
            .then(() => {
                axios.get('/slides')
                    .then((response) => {
                        const { slides } = response.data
                        this.setState({
                            myData: slides
                        })
                    })
            })
        this.toggleDialogSingle()
        window.location.reload()
        toast.success("Successfully Deleted !")
    };

    renderEditable = (cellInfo) => {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const data = [...this.state.myData];
                    data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ myData: data });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.myData[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    Capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    componentDidMount = () => {
        this.props.actionsdetailRole(localStorage.getItem('roles'))

        setTimeout(() => {
            if (this.props.roledetails.role[0]) {
                if (this.props.roledetails.role[0].supprimerSlide == '1') {
                    this.setState({
                        deletable: true
                    })
                }
                if (this.props.roledetails.role[0].modifierSlide == '1') {
                    this.setState({
                        updatable: true
                    })
                }
            }
        }, 1000)
    }

    render() {
        const { pageSize, myClass, check, multiSelectOption, pagination } = this.props;
        const { myData, deletable, updatable } = this.state

        const columns = [];
        for (var key in myData[0]) {

            let editable = this.renderEditable
            if (key === "image") {
                editable = null;
            }
            if (key === "status") {
                editable = null;
            }
            if (key === "avtar") {
                editable = null;
            }
            if (key === "vendor") {
                editable = null;
            }
            if (key === "order_status") {
                editable = null;
            }

            columns.push(
                {
                    Header: <b>{this.Capitalize(key.toString())}</b>,
                    accessor: key,
                    Cell: editable,
                    style: {
                        textAlign: 'center'
                    }
                });
        }


        if (multiSelectOption == true) {
            if ((deletable == true) && (updatable == true)) {
                columns.push(
                    {
                        Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                            onClick={() => this.toggleDialog()}>Delete</button>,
                        id: 'delete',
                        accessor: str => "delete",
                        sortable: false,
                        style: {
                            textAlign: 'center'
                        },
                        Cell: (row) => (
                            // console.log(row)
                            <div>
                                <span >
                                    <input type="checkbox" name={row.original.id}
                                        defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                        onChange={e => this.selectRow(e, row.original.id)} />
                                </span>
                                <span><Link to={`/slides/edit-slide/${row.original.id}`}>
                                    <i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }}></i>
                                </Link></span>
                            </div>

                        ),
                        accessor: key,
                        style: {
                            textAlign: 'center'
                        }
                    }
                )
            } else if (deletable == true && updatable == false) {
                columns.push(
                    {
                        Header: <button className="btn btn-danger btn-sm btn-delete mb-0 b-r-4"
                            onClick={() => this.toggleDialog()}>Delete</button>,
                        id: 'delete',
                        accessor: str => "delete",
                        sortable: false,
                        style: {
                            textAlign: 'center'
                        },
                        Cell: (row) => (
                            // console.log(row)
                            <div>
                                <span >
                                    <input type="checkbox" name={row.original.id}
                                        defaultChecked={this.state.checkedValues.includes(row.original.id)}
                                        onChange={e => this.selectRow(e, row.original.id)} />
                                </span>
                            </div>

                        ),
                        accessor: key,
                        style: {
                            textAlign: 'center'
                        }
                    }
                )
            } else if (deletable == false && updatable == true) {
                columns.push(
                    {
                        Cell: (row) => (
                            // console.log(row)
                            <div>
                                <span><Link to={`/slides/edit-slide/${row.original.id}`}>
                                    <i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }}></i>
                                </Link></span>
                            </div>
                        ),
                        style: {
                            textAlign: 'center'
                        }
                    }
                )
            }

        } else {
            columns.push(
                {
                    Header: <b>Action</b>,
                    id: 'delete',
                    accessor: str => "delete",
                    Cell: (row) => (
                        <div>
                            <span onClick={() => this.handleDialogSingle(row.original.id)
                                // {
                                //     if (window.confirm('Are you sure you wish to delete this item?')) {
                                //         let data = myData;
                                //         data.splice(row.index, 1);
                                //         this.setState({ myData: data });
                                //     }
                                //     toast.success("Successfully Deleted !")

                                // }
                            }>
                                <i className="fa fa-trash" style={{ width: 35, fontSize: 20, padding: 11, color: '#e4566e' }}
                                ></i>
                            </span>

                            <span><i className="fa fa-pencil" style={{ width: 35, fontSize: 20, padding: 11, color: 'rgb(40, 167, 69)' }}></i></span>
                        </div>
                    ),
                    style: {
                        textAlign: 'center'
                    },
                    sortable: false
                }
            )
        }

        return (
            <Fragment>
                <ReactTable
                    data={myData}
                    columns={columns}
                    defaultPageSize={pageSize}
                    className={myClass}
                    showPagination={pagination}
                />
                <ToastContainer />

                <Dialog
                    hidden={this.state.hiddenDialog}
                    onDismiss={() => this.toggleDialog()}
                    dialogContentProps={this.dialogContentProps}
                    isBlocking={true}
                // modalProps={modalProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.toggleDialog()} text="Annuler" />
                        <DefaultButton onClick={() => this.handleRemoveRow()} text="Supprimer" style={{ backgroundColor: '#dc3545', color: '#fff' }} />
                    </DialogFooter>
                </Dialog>

                <Dialog
                    hidden={this.state.hiddenDialogSingle}
                    onDismiss={() => this.toggleDialogSingle()}
                    dialogContentProps={this.dialogContentPropsSingle}
                    isBlocking={true}
                // modalProps={modalProps}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.toggleDialogSingle()} text="Annuler" />
                        <DefaultButton onClick={() => this.handleRemoveRowSingle()} text="Supprimer" style={{ backgroundColor: '#dc3545', color: '#fff' }} />
                    </DialogFooter>
                </Dialog>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        roledetails: state.roledetails
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        actionsdetailRole: (rolename) => { dispatch(roleActions.actionsdetailRole(rolename)) },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Data_slides)
